import fetchApi from '../../services/fetch';
import { coAPI } from '../../constants/api';
import { track, generateServerResponseError, generateMessageDisplayedSegment } from '../../utils/segmenTracker';
import { SERVER_RESPONSE_FAILED, MESSAGE_DISPLAYED, ACTIVATION, INELIGIBILITY_NOTIFIED, SERVER_RESPONSE_OK } from '../../constants/segmentEvents';

const initialState = {
  data: {
    department: '',
    city: '',
    streetType: '',
    streetNumber: '',
    streetHash: '',
    streetDash: '',
    aptNo: '',
    telephone: '',
    termsAndConditions: false,
  },
  locations: {},
  normalizedAddress: {},
  coverage: {},
  normalizedAddressError: false,
  eCommerceData: {},
  qParams: {},
};

export const inputAddress = {
  state: { ...initialState },
  reducers: {
    updateInputAddressState(state, payload) {
      return { ...payload };
    },
    updateAddress: (state, payload) => {
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    },
    updateSocialStratus: (state, payload) => {
      return { ...state, normalizedAddress: payload };
    },
    updateLocation(state, payload) {
      return { ...state, locations: { ...state.locations, ...payload } };
    },
    updateCoverage: (state, payload) => {
      return { ...state, coverage: payload };
    },
    clearAddress: (state) => {
      return { ...initialState, eCommerceData: state.eCommerceData, qParams: state.qParams };
    },
    setNormalizedAddressError: (state, payload) => {
      return { ...state, normalizedAddressError: payload };
    },
    clearAllExceptLocation: (state) => {
      return {
        ...initialState,
        locations: state.locations,
        eCommerceData: state.eCommerceData,
        qParams: state.qParams,
      };
    },
    updateEcommerceData: (state, payload) => {
      return { ...state, eCommerceData: payload };
    },
    updateQParams(state, payload) {
      return { ...state, qParams: payload };
    },
  },
  effects: (dispatch) => {
    return {
      async getSocialStratus(params = {}) {
        const { city, streetType, streetNumber, streetHash, streetDash, callBack, streetName, cityName, errorCallback, diffLoad = false } = params;
        dispatch.inputAddress.updateSocialStratus({});
        if (diffLoad) {
          dispatch.apiStatus.setLoader(true);
        } else {
          dispatch.apiStatus.setProcessing(true);
        }
        this.setNormalizedAddressError(false);
        return fetchApi({
          method: 'post',
          url: coAPI.getSocialStratus,
          data: {
            location: {
              city: cityName || city?.CCP,
              street: streetName || `${streetType?.displayName} ${streetNumber} # ${streetHash} - ${streetDash}`,
            },
          },
        })
          .then((response) => {
            const {
              data: { normalizedAddress },
            } = response;
            dispatch.inputAddress.updateSocialStratus(normalizedAddress);
            callBack(normalizedAddress);
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.getSocialStratus,
            });
            dispatch.apiStatus.setLoader(false);
            return normalizedAddress;
          })
          .catch((error) => {
            this.setNormalizedAddressError(true);
            let message = error?.message?.message || error?.message;
            if (error.errorCode === 404) {
              message = 'No hemos identificado tu dirección. Verifica que el pin este ubicado correctamente en el mapa o escribe nueva nuevamente tu dirección.';
              const paymentTrack = {
                debt_amount: '',
                invoice_collect_date: '',
                invoice_count: '',
                payment_account: '',
                journey: ACTIVATION,
              };
              track(INELIGIBILITY_NOTIFIED, paymentTrack);
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Direccion Detallada', coAPI.getSocialStratus, 'Normalization API', message),
            });
            track(MESSAGE_DISPLAYED, {
              ...generateMessageDisplayedSegment(error, ACTIVATION, 'Normalization API', message),
            });
            dispatch.apiStatus.setLoader(false);
            errorCallback();
          });
      },
      async getCoverage({ callBack, diffLoad = false, ...params }, state) {
        if (diffLoad) {
          dispatch.apiStatus.setLoader(true);
        } else {
          dispatch.apiStatus.setProcessing(true);
        }
        return fetchApi({
          method: 'POST',
          url: coAPI.coverage,
          data: { ...params },
        })
          .then((response) => {
            const {
              data: { coverage },
            } = response;
            dispatch.inputAddress.updateCoverage(coverage);
            callBack(coverage, params);
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.coverage,
            });
            dispatch.apiStatus.setLoader(false);
            return coverage;
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Direccion Detallada', coAPI.coverage, 'Coverage API'),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(error, ACTIVATION, 'Coverage API') });
            dispatch.apiStatus.setRequestFailed({ ...error });
            dispatch.apiStatus.setLoader(false);
            callBack(error);
          });
      },
      async getNormAddrees(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.getSocialStratus,
          data: { ...payload },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.getSocialStratus,
            });
            return Promise.resolve({ success: true, response: response?.data?.normalizedAddress });
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Personal information', coAPI.getSocialStratus, 'Normalized address API'),
            });
            return Promise.resolve({ success: false, error });
          });
      },

      async getGeoAddress(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.geoReference,
          data: { ...payload },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.geoReference,
            });
            return Promise.resolve({ success: true, response });
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Personal information', coAPI.geoReference, 'Geo reference API'),
            });
            return Promise.resolve({ success: false, error });
          });
      },

      async userEligibility(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.packages,
          data: { ...payload },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.packages,
            });
            return Promise.resolve({ success: true, response });
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Personal information', coAPI.packages, 'Offers eligibility API'),
            });
            return Promise.resolve({ success: false, error });
          });
      },

      async coveragenetwork(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.coveragenetwork,
          data: { ...payload },
          headers: {
            'X-PlatformId': 'platformid',
            'X-TransactionId': 'transactionid',
            'X-SourceIp': '10.10.10.10',
          },
        })
          .then((response) => {
            const {
              data: { coverage },
            } = response;
            dispatch.inputAddress.updateCoverage(coverage);
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.coveragenetwork,
            });
            return Promise.resolve({ success: true, response: response?.data });
          })
          .catch((error) => {
            dispatch.inputAddress.updateCoverage({
              "hasCoverage": false,
              "externalOperator": true
            });
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Personal information', coAPI.coveragenetwork, 'Coveragenetwork API'),
            });
            return Promise.resolve({ success: false, error });
          });
      },
    };
  },
};
