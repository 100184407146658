import _ from 'lodash';
import { config } from 'constants/index';
import estados from 'constants/estados';
import { encryptData } from 'utils/secureTransfer';
import { accentReplace } from 'utils/textFormatter';
import { isEmpty, getSpanishMonths, removeAccents, numberFormatting, FormatAmt } from './utils';
import { updateGlobalTracker } from './segmenTracker';

const ciudades = require('constants/ciudades.json');

async function zendeskParams(props, zendeskModel) {
  const {
    locations: { cliTelephone },
    normalizedAddress: { location },
  } = props.inputAddress;
  let { brand_id, subject, ticket_form_id, tags, custom_fields, name, email, additional_tags = null } = zendeskModel;
  let customFields = custom_fields;
  customFields[4].value = customFields[4].value || cliTelephone?.replace(/[^\d]/g, '');
  customFields[7].value = _.get(location, 'address', '');

  let req = {};
  if (email) {
    req = { email: `${email}`, name: name || ' ' };
  } else {
    req = { phone: cliTelephone?.replace(/[^\d]/g, ''), name: cliTelephone?.replace(/[^\d]/g, '') };
  }

  let queryParams = {
    ticket: {
      requester: {
        ...req,
      },
      subject,
      comment: await getCommentBody(props, zendeskModel),
      tags: tags,
      brand_id: brand_id,
      ticket_form_id: ticket_form_id,
      custom_fields: custom_fields,
    },
  };
  if (additional_tags) queryParams.ticket['additional_tags'] = additional_tags;
  else delete queryParams.ticket.additional_tags;

  return queryParams;
}

export const getCommentBody = async (props, zendeskModel, price) => {
  let { commentBody, acceptText, bodyParam = null, extras = '' } = zendeskModel;
  const {
    inputAddress: {
      locations = {},
      normalizedAddress: { location = {} },
      coverage = {},
    },
    registration: {
      data: {
        cliFirstName = '',
        cliLastName = '',
        cliEmail = '',
        documentNumber = '',
        documentType = '',
        expiryDate = '',
        expiryMonth = '',
        expiryYear = '',
        phoneVerified = '',
        emailVerified = '',
        cliTelephone = '',
        Evidente = '',
        LISIM = '',
        ROC = '',
        Agendamiento = '',
        Confirmacion = '',
        PermanenciaMinima = '',
        RenovacionAutomatica = '',
        FacturaElectronica = '',
        ConsentimientoExpreso = '',
        AceptacionContratoFijo = '',
        UtmCampaign = '',
      },
      dataCreditoInfo = {},
    },
    packages: { currentSelected = [] },
  } = props;

  const {
    streetType: { displayName: streetType = '' } = {},
    streetNumber = '',
    streetHash = '',
    streetDash = '',
    city: { displayName = '' } = {},
    department: { provinceName = '' } = {},
    aptNo,
    cliTelephone: telephone = '',
    cityCode,
    tower,
    apartment,
    ...geoReferenceData
  } = locations;

  const enteredAddress = `${streetType} ${streetNumber} # ${streetHash} - ${streetDash}`;

  const { address = '', socialStratus = '' } = location;

  const { name = '', id = '', resourceList = [] } = currentSelected[0] ? currentSelected[0] : '';
  const arrAddOn = currentSelected
    ?.filter((selection) => selection?.offerType === 'activation supplementary')
    ?.map((addon) => addon?.name);
  const internet = getDisplayTextFromResourceList('Internet', resourceList);
  const television = getDisplayTextFromResourceList('Television', resourceList);
  const telefonia = getDisplayTextFromResourceList('Telefonia', resourceList);
  const decos = getDisplayTextFromResourceList('Decos', resourceList);
  const beneficios = getDisplayTextFromResourceList('Beneficios', resourceList);

  const selectedAddons = () =>
    currentSelected?.filter((selection) => selection?.offerType === 'activation supplementary');
  const bundle = () => currentSelected?.filter((selection) => selection?.offerType === 'activation primary');

  const totalPrice = [...bundle(), ...selectedAddons()].reduce(
    (acc, curr) => Number(acc) + Number(curr?.priceList?.[0]?.value),
    '0'
  );
  const displayPrice = numberFormatting(totalPrice, 'en-US');

  const departmentSelected = estados?.find((estado) => estado?.provinceCode === geoReferenceData?.departamentCode);
  const muncipalitySelected = ciudades?.find((ciudade) => ciudade?.CCP === geoReferenceData?.municipalityCode);
  const clientValidity = validateDocument({ registration: props.registration.data, dataCreditoInfo }) ? 'SI' : 'NO';
  let coverageRN = '';
  if (coverage?.hasCoverage === true || coverage?.hasCoverage === 'true' ) {
    coverageRN = coverage?.technologyList[0]?.externalOperator ? 'Sí' : 'No';
  } else if (coverage?.hasCoverage === false) {
    coverageRN = coverage?.externalOperator ? 'Sí' : 'No';
  }
  let coverageRName = '';
  if (coverageRN === 'Sí') {
    coverageRName = coverage.technologyList? coverage.technologyList[0]?.externalOperator || '' : '';
  }
  updateGlobalTracker({
    state: departmentSelected?.provinceName || provinceName || '',
    city: muncipalitySelected?.NM || displayName || cityCode || '',
    msisdn: telephone?.replace(/[^\d]/g, '') || cliTelephone?.replace(/[^\d]/g, '') || '',
    address: geoReferenceData?.naturalAddress || enteredAddress || '',
  });

  let utm = `'UTM Campaign': ${UtmCampaign || 'eCommerce Home'}`;

  const combody = `${commentBody}
  Nombres del solicitante: ${cliFirstName ? accentReplace(cliFirstName) : ':nombres'},
  Apellidos del solicitante: ${cliLastName ? accentReplace(cliLastName) : ':apellidos'},
  Tipo Documento: ${documentNumber && documentType ? documentType : ':tipo_documento'}, 
  Número Documento: ${documentNumber ? documentNumber?.replace(/[^\d]/g, '') : ':nro_documento'}, 
  Fecha Expedicion documento: ${
    expiryMonth
      ? `${formatExpiryDate(expiryDate)}-${formatExpiryMonth(expiryMonth)}-${expiryYear}`
      : ':fecha_expedicion'
  }, 
  Estrato: ${geoReferenceData?.stratum || ':estrato'},
  Departamento: ${departmentSelected?.provinceName || provinceName || ':departamento'}, 
  Ciudad: ${muncipalitySelected?.NM || displayName || cityCode || ':ciudad'}, 
  Direccion: ${geoReferenceData?.naturalAddress || enteredAddress || ':direccion'},
  Torre/Bloque: ${tower || ':torre'},
  Apartamento: ${apartment || ':apt'},
  Otro: ${aptNo || ':otro'},
  Validó cobertura tercero: ${coverageRN},
  Nombre de validador tercero: ${coverageRName},
  Numero de movil: ${telephone || cliTelephone ? telephone.replace(/[^\d]/g, '') || cliTelephone.replace(/[^\d]/g, '') : ':numero_movil'}, 
  Acepto Politicas de privacidad y tratamiento de datos Personales: ${acceptText ? acceptText : ':acepto_politicas'}, 
  Plan seleccionado: ${name ? name : ':nombre_plan'}, 
  ID plan seleccionado: ${id ? id : ':id_plan'},
  Internet: ${internet || ':internet'}, 
  Television: ${television || ':television'}, 
  Telefonia: ${telefonia || ':telefonia'}, 
  Beneficios: ${beneficios || ':beneficios'}, 
  Decos incluidos: ${decos || ':decos_incluidos'} , 
  AddOns: ${arrAddOn?.length ? arrAddOn : ':addons'}, 
  Valor plan: ${price ? price : (Number(displayPrice) ? FormatAmt(displayPrice) : ':price_plan')},
  Correo Electrónico: ${cliEmail ? cliEmail : ':email'}, 
  Dirección del cliente: ${geoReferenceData?.naturalAddress || enteredAddress || ':direccion_cliente'}, 
  Cliente validado: ${clientValidity},
  Email en DAR Validado: ${typeof emailVerified === 'boolean' ? email(emailVerified) : ':email_dar_validado'},
  Telefono en DAR Validado: ${typeof phoneVerified === 'boolean' ? phone(phoneVerified) : ':telefono_dar_validado'},
  Evidente: ${bodyParam?.Evidente || Evidente || ':evidente'},
  LISIM: ${bodyParam?.LISIM || LISIM || ':LISIM'},
  ROC: ${bodyParam?.ROC || ROC || ':ROC'},
  ${extras}
  Agendamiento: ${bodyParam?.Agendamiento || Agendamiento || ':agendamiento'},
  Confirmacion: ${bodyParam?.Confirmacion || Confirmacion || ':confirmacion'}
  PermanenciaMinima: ${
    [true, false].includes(bodyParam?.PermanenciaMinima || PermanenciaMinima)
      ? bodyParam?.PermanenciaMinima || PermanenciaMinima
      : ':PermanenciaMinima'
  },
  RenovacionAutomatica: ${bodyParam?.RenovacionAutomatica || RenovacionAutomatica || ':RenovacionAutomatica'},
  FacturaElectronica: ${
    [true, false].includes(bodyParam?.FacturaElectronica || FacturaElectronica)
      ? bodyParam?.FacturaElectronica || FacturaElectronica
      : ':FacturaElectronica'
  },
  ConsentimientoExpreso: ${bodyParam?.ConsentimientoExpreso || ConsentimientoExpreso || ':ConsentimientoExpreso'},
  AceptacionContratoFijo: ${
    [true, false].includes(bodyParam?.AceptacionContratoFijo || AceptacionContratoFijo)
      ? bodyParam?.AceptacionContratoFijo || AceptacionContratoFijo
      : ':AceptacionContratoFijo'
  },
  ${utm}
  `;

  console.log(`%cZendesk body: ${combody}`, 'padding: 10px; background: black; color: yellow;');
  const body = await encryptData(combody);

  return {
    body,
    public: true,
  };
};

export const email = (emailVerified) => {
  return `${emailVerified ? 'SI' : 'NO'}`;
};

export const phone = (phoneVerified) => {
  return `${phoneVerified ? 'SI' : 'NO'}`;
};

export default zendeskParams;

const formatExpiryDate = (date) => {
  if (!date) return date;
  const parsedDate = date?.toString();
  const formattedDate = parsedDate?.length < 2 ? `0${parsedDate}` : parsedDate;
  return formattedDate;
};

const formatExpiryMonth = (month) => {
  if (!month) return month;
  const monthObjFromConfig = config?.months?.find((m) => m?.label === month);
  return monthObjFromConfig?.value || '';
};

const getDisplayTextFromResourceList = (key, resourceList) => {
  const filteredList = resourceList?.filter((resource) => resource?.name?.toLowerCase() === key.toLowerCase());
  if (!filteredList?.length) return '';
  return filteredList?.reduce((acc, curr) => acc + ' ' + curr?.value, '')?.trim();
};

const validateDocument = ({ registration, dataCreditoInfo }) => {
  if (isEmpty(dataCreditoInfo) || isEmpty(dataCreditoInfo.informacionBasica)) {
    return false;
  }
  let { informacionBasica } = dataCreditoInfo;
  if (informacionBasica.numeroIdentificacion !== registration.documentNumber.replace(/\./g, '')) {
    return false;
  }
  let cliLastNames = removeAccents(registration.cliLastName).toUpperCase().split(' ');
  if (cliLastNames[0].toLowerCase() !== removeAccents(informacionBasica.primerApellido).toLowerCase()) {
    return false;
  }
  let [day, month, year] = informacionBasica.fechaExpedicion.split('/');
  const registrationMonth = getSpanishMonths().indexOf(registration.expirationMonth) + 1;
  if (registrationMonth !== parseInt(month) || registration.expirationYear !== year) {
    return false;
  }
  return true;
};

export const getCustomFields = async (zen, fields) => {
  const customFields = JSON.parse(JSON.stringify(zen));
  fields.forEach((cf) => {
    const ids = customFields.map((z) => z.id);
    if (ids.includes(cf.id)) {
      const fIndex = customFields.findIndex((obj) => obj.id == cf.id);
      if (cf.value !== '') {
        customFields[fIndex].value = cf.value;
      }
    } else {
      customFields.push(cf);
    }
  });
  return customFields;
};
