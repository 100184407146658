import uuidv1 from 'uuid/v1';
import { getPubKeyFEJwk } from 'utils/secureTransfer';
import secureStorage from 'utils/secureWebStorage';
import { deleteCookie } from 'utils/utils';
import { get } from 'lodash';
import { updateNpsKeys } from 'utils/nps';
import fetchApi from '../../services/fetch';
import { coAPI } from '../../constants/api';
import { getCountry } from '../../utils/utils';
import { track } from '../../utils/segmenTracker';
import {
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
  ACTIVATION,
  SERVER_RESPONSE_OK,
} from '../../constants/segmentEvents';
import { pageURL_CO } from 'constants/index';

const getUUID = () => {
  return `${`${new Date().getTime()}`.slice(-10)}-${uuidv1()}`;
};

const initialState = {
  data: {},
  flow: '',
  uuid: `${new Date().getTime()}`.slice(-10),
  orderIDTXS: `${new Date().getTime()}`.slice(-10),
};

export const auth = {
  state: { ...initialState },
  reducers: {
    updateAuthData(state, payload) {
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    },
    setFlow(state, payload) {
      return {
        ...state,
        flow: payload,
      };
    },
  },

  effects: (dispatch) => {
    return {
      async authenticate() {
        window.millicom = { nps: {} };
        this.clearStore()
        await deleteCookie();
        await localStorage.clear();
        dispatch.auth.updateAuthData({ isLoggedIn: false });
        dispatch.apiStatus.setProcessing(true);
        const { publicJwk, resPubJwk } = await getPubKeyFEJwk();
        fetchApi({
          method: 'POST',
          url: coAPI.accessToken,
          data: {
            country: getCountry(),
            publicJwk,
            resPubJwk,
          },
          headers: {
            exclude: true,
          },
        })
          .then(async (res) => {
            secureStorage.setItem('BEPubKey', res?.data?.publicJwk);
            updateNpsKeys({});
            dispatch.auth.updateAuthData({ isLoggedIn: true });
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...res?.data }],
              endpoint: coAPI.accessToken,
            });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            const additionalData = {
              journey: ACTIVATION,
              screen_name: 'Enter Address',
              endpoint: coAPI.accessToken,
              error_message: error.message,
              error_type: error.errorCode,
              error_event: 'Access Token API',
              error_id: error.errorCode,
              raw_response: [{ ...error }],
            };
            track(SERVER_RESPONSE_FAILED, additionalData);
            track(MESSAGE_DISPLAYED, {
              journey: ACTIVATION,
              message: error.message,
              event_type: 'error',
              error_event: 'Access Token API',
              error_id: error.errorCode,
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async checkMaintanence({ onMaintanence, offMaintanence }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'GET',
          url: coAPI.maintenance,
          headers: {
            exclude: true,
            excludeRes: true,
          },
        })
          .then((response) => {
            if (get(response.data, 'executionInfo[0].value', '') === 1) {
              onMaintanence && onMaintanence(pageURL_CO.maintenance);
            } else {
              offMaintanence && offMaintanence();
            }
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
          });
      },
      async checkModuleStatus({ callBack, module }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'GET',
          url: module ? coAPI.moduleStatus.replace(':moduleCode', module) : coAPI.moduleStatusAll,
          headers: {
            exclude: true,
            excludeRes: true,
          },
        })
          .then((response) => {
            if (callBack) callBack(response?.data?.executionInfo);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
          });
      },
      async clearStore() {
        dispatch({ type: 'RESET_APP' });
      },
    };
  },
};
