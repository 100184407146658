export const updateNpsKeys = (data = {}, update = false) => {
  if (!update) {
    window.millicom = {
      nps: {
        plan_type: 'Pospago',
        product_type: 'Fijo',
        channel_type: 'WEB',
        country: 'CO',
        user_email: data?.cliEmail || '',
        purchase: 'ESALES_GROSS',
        transaction_result: 'SUCCESS',
        transaction_id: '',
        user_full_name: data?.cliFirstName ? `${data.cliFirstName} ${data.cliLastName}` : '',
        user_DocumentNumber: data?.documentNumber || '',
        b2b_flag: '0',
        contact_number: data?.cliTelephone?.replace(/[- )(]/g, '') || '',
      },
    };
  } else if (update && window.millicom?.nps && typeof window.millicom?.nps === 'object') {
    window.millicom.nps = { ...window.millicom.nps, ...data };
  }
};
