import { getCountry } from './utils';
import { getDevice, getOS } from './deviceInfo';
import { globalSegmentsCR } from './globalSegmentsCR';
import { defaultTrackValuesNI } from './segmentTracker/NI/defaultTrackValuesNI';
import { globalSegmentsBO } from './globalSegmentsBO';

const { vendor, model, type } = getDevice();
const { name } = getOS();

export const defaultTrackValues = {
  GT: {
    authentication_method: 'tigoId',
    authentication_provider: 'msisdn',
    connection_type: 'Mobile',
    // page_name: null,
    // prev_page: null,
    segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
    msisdn: '',
    version: process.env.REACT_APP_SEGMENT_VERSION,
    business_category: '',
    lead_id: '',
    poa_id: '',
    poa_sales_channel: '',
    carrier: '',
    poa_first_name: '',
    poa_last_name: '',
    poa_at_home: '',
    device_id: window?.MediaDeviceInfo?.deviceId || '',
    device_manufacturer: vendor || '',
    device_model: model || '',
    device_height: window.innerHeight,
    device_platform: name || '',
    device_type: type,
    ip: '',
    msisdn_anon_user: '',
    state: '',
    city: '',
    town: '',
    address: '',
    text_content: 'activation',
    home_reference_id: '',
    home_location: '',
    device_location: '',
    product_default: '',
    products: '',
    promotion_name: '',
    plan_price: '',
    tax_amount: '',
    fee_amount: '',
    currency: 'Q',
    national_id_type: '',
    national_id: '',
    user_name: '',
    email: '',
    expiration_date: '',
    issuance_date: '',
    birth_date: '',
    gender: '',
    email_validation: '',
    id_validation: '',
    msisdn_validation: '',
    linked_msisdns: '',
    reference_added: '',
    terms_and_conditions: '',
    'phone OTP status': '',
    'email OTP status': '',
    visit_date: '',
    visit_time: '',
    visit_type: '',
    photo_capture_front: '',
    photo_capture_rear: '',
    map_zoom_scale: '',
    contract_id: '',
    automatic_payment_enabled: '',
    service_available: '',
    credit_available: '',
    existing_client: '',
  },
};

export const defaultTrackValuesPY = {
  authentication_method: 'tigoId',
  authentication_provider: 'msisdn',
  connection_type: 'Mobile',
  page_name: '',
  prev_page: '',
  segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
  msisdn: '',
  version: process.env.REACT_APP_SEGMENT_VERSION,
  business_category: 'Home',
  lead_id: '',
  poa_id: '',
  poa_sales_channel: '',
  poa_first_name: '',
  poa_last_name: '',
  poa_at_home: '',
  carrier: '',
  device_id: window?.MediaDeviceInfo?.deviceId || '',
  device_manufacturer: vendor || '',
  device_model: model || '',
  device_height: window.innerHeight,
  device_platform: name || '',
  device_type: type,
  ip: '',
  msisdn_anon_user: '',
  state: '',
  city: '',
  town: '',
  address: '',
  text_content: 'activation',
  home_location: '',
  device_location: '',
  product_default: '',
  products: '',
  product_category: '',
  promotion_name: '',
  plan_price: '',
  tax_amount: '',
  fee_amount: '',
  currency: 'Gs',
  national_id_type: '',
  national_id: '',
  user_name: '',
  email: '',
  expiration_date: '',
  issuance_date: '',
  birth_date: '',
  gender: '',
  email_validation: '',
  id_validation: '',
  msisdn_validation: '',
  manual_input: '',
  linked_msisdns: '',
  reference_added: '',
  terms_and_conditions: '',
  payment_method: '',
  'phone OTP status': '',
  'email OTP status': '',
  visit_date: '',
  visit_time: '',
  visit_type: '',
  photo_capture_front: '',
  photo_capture_rear: '',
  map_zoom_scale: '',
  contract_id: '',
  automatic_payment_enabled: '',
  service_available: '',
};

export let globalTrackValues = {
  SV: {
    authentication_method: 'tigoId',
    authentication_provider: 'msisdn',
    connection_type: 'Mobile',
    page_name: null,
    prev_page: null,
    segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
    msisdn: '',
    version: process.env.REACT_APP_SEGMENT_VERSION,
    business_category: 'Home',
    lead_id: '',
    poa_id: '',
    poa_sales_channel: 'D2D',
    carrier: '',
    poa_first_name: '',
    poa_last_name: '',
    poa_at_home: '',
    device_id: window?.MediaDeviceInfo?.deviceId || '',
    device_manufacturer: vendor || '',
    device_model: model || '',
    device_height: window.innerHeight,
    device_platform: name || '',
    device_type: type,
    ip: '',
    msisdn_anon_user: '',
    state: '',
    city: '',
    town: '',
    address: '',
    text_content: 'activation',
    technology: '',
    node: '',
    home_reference_id: '',
    home_location: '',
    device_location: '',
    product_default: '',
    products: '',
    product_category: '',
    promotion_name: '',
    plan_price: '',
    tax_amount: '',
    fee_amount: '',
    currency: 'USD',
    national_id_type: '',
    national_id: '',
    user_name: '',
    email: '',
    expiration_date: '',
    issuance_date: '',
    birth_date: '',
    gender: '',
    email_validation: '',
    id_validation: '',
    msisdn_validation: '',
    manual_input: '',
    linked_msisdns: '',
    reference_added: '',
    terms_and_conditions: '',
    payment_method: '',
    'phone OTP status': '',
    'email OTP status': '',
    visit_date: '',
    visit_time: '',
    visit_type: '',
    photo_capture_front: '',
    photo_capture_rear: '',
    map_zoom_scale: '',
    contract_id: '',
    automatic_payment_enabled: '',
    service_available: '',
    credit_available: '',
  },
  CO: {
    authentication_method: 'tigoId',
    authentication_provider: 'tigoId',
    connection_type: 'Mobile',
    segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
    page_name: null,
    prev_page: null,
    version: process.env.REACT_APP_SEGMENT_VERSION,
    poa_sales_channel: 'unassisted',
    email_validation: 'yes',
    id_validation: 'no',
    msisdn_validation: 'no',
    ip: '',
    'phone OTP status': '',
    'email OTP status': '',
    device_manufacturer: vendor || '',
    device_model: model || '',
    device_platform: name || '',
    device_type: type,
    device_height: window.innerHeight || '',
    device_id: window?.MediaDeviceInfo?.deviceId || '',
    lead_id: '',
    state: '',
    city: '',
    town: '',
    address: '',
    product_default: '',
    products: '',
    plan_price: '',
    national_id_type: '',
    national_id: '',
    user_name: '',
    email: '',
    issuance_date: '',
    business_category: 'Home',
    msisdn: '',
    msisdn_anon_user: '',
    promotion_name: '',
    currency: 'USD',
    carrier: '',
    credit_available: '',
    terms_and_conditions: '',
  },
  HN: {
    authentication_method: 'tigoId',
    authentication_provider: 'msisdn',
    connection_type: 'Mobile',
    page_name: '',
    prev_page: '',
    segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
    msisdn: '',
    version: process.env.REACT_APP_SEGMENT_VERSION,
    business_category: 'Home',
    lead_id: '',
    poa_id: '',
    poa_sales_channel: '',
    poa_first_name: '',
    poa_last_name: '',
    poa_at_home: '',
    carrier: '',
    device_id: window?.MediaDeviceInfo?.deviceId || '',
    device_manufacturer: vendor || '',
    device_model: model || '',
    device_height: window.innerHeight,
    device_platform: name || '',
    device_type: type,
    ip: '',
    msisdn_anon_user: '',
    state: '',
    city: '',
    town: '',
    address: '',
    text_content: 'activation',
    home_location: '',
    device_location: '',
    product_default: '',
    products: '',
    product_category: '',
    promotion_name: '',
    plan_price: '',
    tax_amount: '',
    fee_amount: '',
    currency: 'USD',
    national_id_type: '',
    national_id: '',
    user_name: '',
    email: '',
    expiration_date: '',
    issuance_date: '',
    birth_date: '',
    gender: '',
    email_validation: '',
    id_validation: '',
    msisdn_validation: '',
    manual_input: '',
    linked_msisdns: '',
    reference_added: '',
    terms_and_conditions: '',
    payment_method: '',
    'phone OTP status': '',
    'email OTP status': '',
    visit_date: '',
    visit_time: '',
    visit_type: '',
    photo_capture_front: '',
    photo_capture_rear: '',
    map_zoom_scale: '',
    contract_id: '',
    automatic_payment_enabled: '',
    service_available: '',
  },
  PY: defaultTrackValuesPY,
  NI: defaultTrackValuesNI,
  GT: { ...defaultTrackValues['GT'] },
  CR: globalSegmentsCR,
  BO: globalSegmentsBO,
};

const country = getCountry();

export const updateGlobalTracker = (payload) => {
  globalTrackValues = { ...globalTrackValues, [country]: { ...globalTrackValues[country], ...payload } };
};

export const identify = (userId, traits) => {
  if (process.env.REACT_APP_SEGMENT_TRACK === 'TRUE' && process.env.REACT_APP_ENVIRONMENT !== 'development') {
    userId ? window.mixpanel.identify(userId, traits) : window.mixpanel.identify(traits);
  }
};

export const track = (event, payload = {}) => {
  if (process.env.REACT_APP_SEGMENT_TRACK === 'TRUE' && process.env.REACT_APP_ENVIRONMENT !== 'development') {
    window.mixpanel.track(event, { ...payload, ...globalTrackValues[country] });
  }
  if (process.env.REACT_APP_GTM_ENABLED === 'TRUE' && process.env.REACT_APP_ENVIRONMENT !== 'development') {
    window.dataLayer.push({ event, ...payload, ...globalTrackValues[country] });
  }
};

export const splitURL = (path) => {
  const pathArr = path.split('/');
  return pathArr?.[1] || pathArr?.[0];
};

export const generateServerResponseError = (error, journey, screen_name, endpoint, error_event, message) => ({
  journey,
  screen_name,
  endpoint,
  error_message: message || error?.message?.message || error?.message || '',
  error_type: error.errorCode,
  error_event,
  error_id: error.errorCode,
  raw_response: [{ ...error }],
});

export const generateMessageDisplayedSegment = (error, journey, error_event, message = '') => ({
  journey,
  message: message || error?.message || error?.message?.message || '',
  event_type: 'error',
  error_event,
  error_id: error.errorCode,
});

export const resetGlobalTrackValues = () => ({
  authentication_method: 'tigoId',
  authentication_provider: 'msisdn',
  connection_type: 'Mobile',
  segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
  msisdn: '',
  version: process.env.REACT_APP_SEGMENT_VERSION,
  business_category: 'Home',
  lead_id: '',
  poa_at_home: '',
  carrier: '',
  device_id: window?.MediaDeviceInfo?.deviceId || '',
  device_manufacturer: vendor || '',
  device_model: model || '',
  device_height: window.innerHeight,
  device_platform: name || '',
  device_type: type,
  ip: '',
  msisdn_anon_user: '',
  state: '',
  city: '',
  town: '',
  address: '',
  text_content: 'activation',
  home_location: '',
  device_location: '',
  product_default: '',
  products: '',
  product_category: '',
  promotion_name: '',
  plan_price: '',
  tax_amount: '',
  fee_amount: '',
  currency: 'USD',
  national_id_type: '',
  national_id: '',
  user_name: '',
  email: '',
  expiration_date: '',
  issuance_date: '',
  birth_date: '',
  gender: '',
  email_validation: '',
  id_validation: '',
  msisdn_validation: '',
  manual_input: '',
  linked_msisdns: '',
  reference_added: '',
  terms_and_conditions: '',
  payment_method: '',
  'phone OTP status': '',
  'email OTP status': '',
  visit_date: '',
  visit_time: '',
  visit_type: '',
  photo_capture_front: '',
  photo_capture_rear: '',
  map_zoom_scale: '',
  contract_id: '',
  automatic_payment_enabled: '',
  service_available: '',
  credit_available: '',
  technology: '',
  node: '',
  home_reference_id: '',
});

export const getEmptyPayTrack = () => ({
  debt_amount: '',
  invoice_collect_date: '',
  invoice_count: '',
  payment_account: '',
  journey: 'activation',
});
