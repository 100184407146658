import fetchApi from '../../services/fetch';
import { coAPI } from '../../constants/api';
import { updateGlobalTracker } from 'utils/segmenTracker';
import {
  getCreateBasketParams,
  getUpateBasketParams,
  getFormattedNumber,
  getEncryptedParams,
  getEncryptedUpdateParams,
  removeSecurityAns,
  getRegistrationData,
} from 'utils/basket';
import { encryptData } from 'utils/secureTransfer';
import { CO_TEXTS } from 'constants/index';

const initialState = {
  transactionId: '',
  lastUrl: '',
  stageData: {},
};

export const basket = {
  state: { ...initialState },
  reducers: {
    updateBasketTransaction(state, payload) {
      return { ...state, transactionId: payload };
    },
    updatePreviousUrl(state, payload) {
      return { ...state, lastUrl: payload };
    },
    updateStageData(state, payload) {
      return { ...state, stageData: payload };
    },
  },

  effects: (dispatch) => {
    return {
      async updateRetainedStore(payload) {
        updateGlobalTracker(payload.trackValues);
        updateGlobalTracker({ encrypted_lead_id: payload.leadId });
        dispatch.inputAddress.updateInputAddressState({ ...payload.inputAddress });
        dispatch.packages.updatePackagesState({ ...payload.packages });
        dispatch.registration.updateRegistrationState({ ...getRegistrationData(payload) });
        dispatch.varification.updateVerificationState({ ...removeSecurityAns(payload) });
        dispatch.zendesk.updateZendeskState({ ...payload.zendesk });
        dispatch.basket.updatePreviousUrl(payload?.nextUrl);
        dispatch.packages.updatePackageList(payload?.list);
        dispatch.packages.updateCurrentSelectedPackage(payload?.selected);
        dispatch.packages.updateCurrentSelectedAddons(payload?.addons);
      },
      async checkBasketExist(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.checkBasket,
          data: { ...payload },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, data: response?.data });
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false });
          });
      },
      async getBasketDetails(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.getBasket,
          data: { ...payload },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, data: response?.data });
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false });
          });
      },
      async createBasket({ url }, state) {
        dispatch.apiStatus.setProcessing(true);
        let phone = state?.inputAddress?.locations?.cliTelephone;
        let payload = getCreateBasketParams(url, phone, state);
        let encrypted = await getEncryptedParams(payload);
        dispatch.basket.updateStageData(payload?.stageData);
        return fetchApi({
          method: 'POST',
          url: coAPI.updateBasket,
          data: { ...encrypted },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            dispatch.basket.updateBasketTransaction(response?.data?.transactionId);
            return Promise.resolve({
              success: true,
              phone: getFormattedNumber(phone),
              transactionId: response?.data?.transactionId,
            });
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false, message: CO_TEXTS.BASKET_FAILS });
          });
      },
      async updateBasket({ url, stage, progress = true }, state) {
        if (progress) dispatch.apiStatus.setProcessing(true);
        let payload = getUpateBasketParams(url, state, stage);
        console.log('antes de enviar al basket payload: ', payload)
        let encrypted = await getEncryptedUpdateParams(payload);
        dispatch.basket.updateStageData(payload?.stageData);
        return fetchApi({
          method: 'PUT',
          url: coAPI.updateBasket,
          data: { ...encrypted },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true });
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false, message: CO_TEXTS.BASKET_FAILS });
          });
      },
      async removeBasket(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'DELETE',
          url: coAPI.updateBasket,
          data: { ...payload },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
          });
      },
      async encodeLead(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.encode,
          data: { value: await encryptData(payload) },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, id: response?.data?.output });
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false });
          });
      },
      async decodeLead(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.decode,
          data: { value: await encryptData(payload) },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            if (response?.data?.verified) {
              return Promise.resolve({ success: true, id: response?.data?.output });
            }
          })
          .catch(() => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: false });
          });
      },
    };
  },
};
