import {
  HN_TEXTS as COMMON_TEXT,
  GT_TEXTS,
  texts,
  pageURL_GT,
  config,
  imageURLs,
  appColors,
} from '../constants';
import { GT_DEPARTMENTS } from 'constants/gt/departments_GT';
import { isValidDateHN } from 'utils/utils';
import { gtCountriesListByName, getPaiseEmission } from 'utils/utils_GT';
import { isIOSDevice } from '../utils/deviceInfo';

export const configGT = {
  HomePage: {
    formFields: [],
    sidebarMenu: [
      {
        title: COMMON_TEXT.HOME_NEW_CLIENT,
        url: pageURL_GT.clientInformation,
        iconType: '',
      },
      {
        title: COMMON_TEXT.HOME_SALES_REPORT,
        url: pageURL_GT.salesReport,
        iconType: '',
      },
      {
        title: COMMON_TEXT.HOME_LOGOUT,
        url: '/',
        iconType: '',
      },
    ],
  },
  ClientInformation: {
    title: 'Información del cliente',
    formFields: [
      {
        type: 'label',
        text: 'Tipo de cliente',
        style: {
          marginTop: '11px',
          fontWeight: 'bold',
          marginBottom: '10px',
          fontSize: '18px',
          color: '#00377B',
        },
      },
      {
        type: 'radio',
        options: [
          { value: '0', text: 'Individual' },
          { value: '1', text: 'Corporativo' },
        ],
        name: 'accountType',
        id: 'accountType',
      },
      {
        type: 'label',
        text: 'Información',
        style: {
          marginTop: '11px',
          fontWeight: 'bold',
          marginBottom: '10px',
          fontSize: '18px',
          color: '#00377B',
        },
      },
      {
        id: 'docTypeAndId',
        type: 'formRow',
        components: [
          {
            flexSpan: 1,
            marginRight: '15px',
            component: [
              {
                name: 'documentType',
                label: 'Tipo',
                id: 'documentType',
                type: 'collapsibleList',
                variant: 'outlined',
                flexSpan: 2,
                options: [
                  { value: 'DPI', label: 'DPI' },
                  { value: 'PA', label: 'Pasaporte' },
                ],
              },
            ],
          },
          {
            flexSpan: 2,
            component: [
              {
                name: 'inputDocumentNumber',
                label: 'Nro. Documento',
                id: 'inputDocumentNumber',
                variant: 'outlined',
                type: 'tel',
                inputProps: {
                  mask: config.mask['GT'].documentNumber,
                  'data-testid': 'documentNumber',
                },
                validations: {
                  maxLength: 30,
                },
                mask: (value) => value.documentType === 'DPI',
              },
            ],
          },
        ],
      },
      {
        type: 'label',
        text: 'Verificar el numero de documento, este no podrá ser cambiado en el futuro',
        style: {
          marginTop: '-10px',
          marginLeft: '12px',
          marginRight: '12px',
          fontSize: '12px',
          color: '#8E8E8E',
        },
      },
      {
        name: 'cliFirstName',
        label: 'Nombres',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
        inputProps: { 'data-testid': 'firstName' },
      },
      {
        name: 'cliLastName',
        label: 'Apellidos',
        id: 'cliLastName',
        type: 'text',
        variant: 'outlined',
        inputProps: { 'data-testid': 'secondName' },
      },
      {
        name: 'cliTelephone',
        label: 'Teléfono',
        id: 'cliTelephone',
        type: 'tel',
        variant: 'outlined',
        helperText: 'Debe comenzar con 3 4 ó 5',
        inputProps: {
          mask: config.mask['GT'].phoneNumber,
          'data-testid': 'telephone',
        },
        validations: {
          minLength: 8,
        },
        mask: true,
      },
      {
        name: 'cliEmail',
        label: 'Correo electrónico',
        id: 'cliEmail',
        type: 'text',
        variant: 'outlined',
        inputProps: { 'data-testid': 'email' },
      },
      {
        name: 'NIT',
        label: 'NIT',
        id: 'NIT',
        type: 'text',
        variant: 'outlined',
        helperText: 'Ingresar NIT con guión.',
        inputProps: { 'data-testid': 'NIT' },
      },
      {
        name: 'cliBusinessName',
        label: 'Razón Social',
        id: 'cliBusinessName',
        type: 'text',
        variant: 'outlined',
        inputProps: { 'data-testid': 'businessName' },
      },
      {
        type: 'button',
        text: 'continuar',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.home,
      nextURL: pageURL_GT.locationServices,
      redirectURL: {
        active: pageURL_GT.activeTransaction,
        existing: pageURL_GT.existingServices,
      },
    },
  },
  ExistingServices: {
    title: 'Hemos encontrado estos servicios asociados a tu DPI',
    subTitle: 'Elige el que quieras modificar, o agrega una nueva residencia',
    formFields: [],
    redirectURLs: {
      prevURL: pageURL_GT.clientInformation,
      nextURL: pageURL_GT.existingServicesModify,
      locationURL: pageURL_GT.locationServices,
      home: pageURL_GT.home,
    },
  },
  ExistingDebt: {
    title: 'Deuda pendiente de pago',
    icon: imageURLs.failed,
    redirectURLs: {
      home: pageURL_GT.home,
      redirectURL: pageURL_GT.clientInformation,
    },
    menu: [
      {
        left: '/images/Sucursal-ic.svg',
        center: 'Sucursales',
        right: '/images/ico_arrow-dropdown.svg',
        url: 'https://www.tigo.com.gt/agencias',
      },
      {
        left: '/images/bancos-ic.svg',
        center: 'Centros de pago',
        right: '/images/ico_arrow-dropdown.svg',
        url: 'https://www.tigo.com.gt/agencias/centros-de-pago',
      },
    ],
  },
  LocationActivation: {
    title: GT_TEXTS.ACTIVATE_LOCATION_TITLE,
    redirectURLs: {
      nextURL: pageURL_GT.inputAddress,
      prevURL: pageURL_GT.clientInformation,
      home: pageURL_GT.home,
    },
  },
  InputAddress: {
    title: GT_TEXTS.INPUT_ADDRESS_TITLE,
    formFields: [
      {
        name: 'department',
        label: 'Departamento',
        id: 'department',
        type: 'autocomplete',
        getOptionLabel: (option) => option['state'] || option,
        variant: 'outlined',
        noOptionsText: 'Sin opciones',
        dependency: ['city'],
        showCustomIcons: false,
        showSuccessIcon: false,
        'data-testid': 'department',
      },
      {
        name: 'city',
        label: 'Municipio',
        id: 'city',
        type: 'autocomplete',
        getOptionLabel: (option) => option['town'] || option,
        variant: 'outlined',
        noOptionsText: 'Sin opciones',
        showCustomIcons: false,
        showSuccessIcon: false,
        'data-testid': 'city',
      },
      {
        name: 'locality',
        label: 'Zona',
        id: 'locality',
        type: 'tel',
        variant: 'outlined',
        inputProps: { 'data-testid': 'locality' },
      },
      {
        type: 'divider',
      },
      {
        type: 'label',
        text: 'Selecciona uno o multiples campos con su valor respectivo',
        style: { fontSize: '16px', lineHeight: '21px', marginBottom: '10px' },
        fontColor: '#565656',
      },
      {
        type: 'flexibleFormRow',
        id: 'address',
        keyId: 'addressType',
        valueId: 'addressValue',
        components: [
          {
            flexSpan: 1,
            marginRight: '15px',
            component: [
              {
                name: 'addressType',
                id: 'addressType',
                type: 'autocomplete',
                label: 'Campo',
                getOptionLabel: (option) => option,
                optionsWidth: 'calc(100vw - 30px)',
                optionsBigWidth: '570px',
                optionsOffset: '-30px',
                variant: 'outlined',
                noOptionsText: 'Sin opciones',
                showCustomIcons: false,
                showSuccessIcon: false,
              },
            ],
          },
          {
            flexSpan: 1,
            component: [
              {
                name: 'addressValue',
                label: 'Valor',
                id: 'addressValue',
                type: 'text',
                variant: 'outlined',
                inputProps: { 'data-testid': 'addressValue' },
                flexDisabled: true,
              },
            ],
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        name: 'addressref',
        label: 'Referencia',
        id: 'addressref',
        type: 'textarea',
        variant: 'outlined',
        rows: 4,
        multiline: true,
        optional: true,
        inputProps: { 'data-testid': 'addressref' },
      },
      {
        type: 'checkbox',
        value: 'home',
        label: 'Me encuentro en la casa del cliente',
        id: 'home',
        inputProps: { 'data-testid': 'home' },
      },
      {
        type: 'button-group',
        buttons: [
          {
            type: 'cancel',
            text: 'regresar',
            color: 'transparent',
            textColor: appColors.palette.teal,
            disabled: false,
            className: 'cancel',
            style: { marginRight: 15 },
          },
          { type: 'submit', text: 'confirmar' },
        ],
      },
    ],
    optionalParams: ['addressref'],
    redirectURLs: {
      prevURL: pageURL_GT.locationServices,
      mapURL: pageURL_GT.map,
      addressListURL: pageURL_GT.addressList,
      home: pageURL_GT.home,
    },
  },
  AddressList: {
    title: 'Direcciones encontradas',
    subTitle: 'Por favor elige la que corresponda con la residencia donde se quiere activar el servicio',
    formFields: [],
    redirectURLs: {
      prevURL: pageURL_GT.inputAddress,
      mapURL: pageURL_GT.map,
      frontIdURL: pageURL_GT.idScanFront,
      home: pageURL_GT.home,
    },
  },
  LocationService: {
    description:
      'Verifica si la ubicación de tu residencia es la correcta. En caso contrario puedes moverla tocando otro punto en el mapa..',
    buttonText: 'CONTINUAR',
    redirectURLs: {
      prevURL: pageURL_GT.inputAddress,
      home: pageURL_GT.home,
      nextURL: pageURL_GT.idScanFront,
    },
  },
  IDFront: {
    title: GT_TEXTS.ID_FRONT_TITLE,
    description: '<span>A continuación tomaremos una foto de <b>la cara frontal</b> del documento de identidad.<span>',
    redirectURLs: {
      nextURL: pageURL_GT.idScanRear,
      prevURL: pageURL_GT.map,
      home: pageURL_GT.home,
      jumpToURL: pageURL_GT.idReview,
    },
    scannerConfig: {
      fileName: 'ID_front_side',
      field: 'idFront',
      getImageFunc: 'uploadFront',
      compressionConfig: {
        maxSizeMB: 4,
        maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
        useWebWorker: true,
      },
    },

    scannerConfigOther: {
      scannerConfig: {
        btnText: 'QUIERO USAR OTRO DOCUMENTO',
        fileName: 'ID_front_side',
        field: 'idFront',
        getImageFunc: 'uploadFront',
        compressionConfig: {
          maxSizeMB: 4,
          maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
          useWebWorker: true,
        },
      },
      transparentButton: true,
      documentType: '',
    },
  },
  IDRear: {
    title: 'Foto posterior',
    description1: 'Ahora tomaremos la foto del reverso del DPI.',
    instruction1: [{ src: '/images/FotoPosterior(Icon 1).svg', description: '' }],
    description2: 'Gira la cámara para un mejor ángulo.',
    instruction2: [{ src: '/images/FotoPosterior(Icon 2).svg', description: '' }],
    scannerConfig: {
      fileName: 'ID_rear_side',
      field: 'idRear',
      getImageFunc: 'uploadFront',
      compressionConfig: {
        maxSizeMB: 4,
        maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
        useWebWorker: true,
      },
      redirectURL: pageURL_GT.home,
    },
    redirectURLs: {
      prevURL: pageURL_GT.idScanFront,
      home: pageURL_GT.home,
      nextURL: pageURL_GT.idReview,
    },
  },
  IDReview: {
    title: GT_TEXTS.HN_ID_REVIEW_CONFIRMATION,
    formFields: [
      {
        name: 'paiseEmission',
        label: 'Pais de emision',
        id: 'paiseEmission',
        type: 'select',
        variant: 'outlined',
        options: [...getPaiseEmission()],
      },
      {
        name: 'issuingCountry',
        label: 'Nacionalidad',
        id: 'issuingCountry',
        type: 'select',
        variant: 'outlined',
        options: [...gtCountriesListByName()],
      },
      {
        name: 'departmento',
        label: 'Departamento',
        id: 'departmento',
        type: 'autocomplete',
        options: [...GT_DEPARTMENTS],
        getOptionLabel: (option) => option['state'] || option,
        variant: 'outlined',
        noOptionsText: 'Sin opciones',
        dependency: ['municipio'],
        showCustomIcons: false,
        showSuccessIcon: false,
        'data-testid': 'department',
      },

      {
        name: 'municipio',
        label: 'Municipio',
        id: 'municipio',
        type: 'autocomplete',
        options: [],
        getOptionLabel: (option) => option['town'] || option,
        variant: 'outlined',
        noOptionsText: 'Sin opciones',
        showCustomIcons: false,
        showSuccessIcon: false,
        'data-testid': 'department',
      },

      {
        name: "documentNumber",
        className: "documentNumber",
        label: "Número de documento",
        id: "documentNumber",
        type: "tel",
        variant: "outlined",
        inputProps: { mask: config.mask["GT"].documentNumber },
        mask: (value) => value.documentType === "DPI",
      },
      {
        name: 'cliFirstName',
        label: 'Nombres',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'cliLastName',
        label: 'Apellidos',
        id: 'cliLastName',
        type: 'text',
        variant: 'outlined',
      },
      {
        text: 'Fecha de nacimiento',
        type: 'label',
        style: { color: '#565656', fontSize: '12px' },
      },
      {
        type: 'formRow',
        components: [
          {
            flexSpan: 1,
            marginRight: '15px',
            component: [
              {
                name: 'birthDate',
                label: 'Día',
                id: 'birthDate',
                type: 'tel',
                variant: 'outlined',
                inputProps: { mask: config.mask['HN'].date },
                validations: {
                  minLength: 2,
                  maxValue: 31,
                },
                mask: true,
                dependency: 'birthDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    value,
                    config.months.find((month) => month.short.toLowerCase() === formData['birthMonth'].toLowerCase())
                      .value,
                    formData['birthYear']
                  ),
              },
            ],
          },
          {
            flexSpan: 2,
            marginRight: '15px',
            component: [
              {
                name: 'birthMonth',
                label: 'Mes',
                id: 'birthMonth',
                type: 'select',
                variant: 'outlined',
                options: config.months.map((month) => month.short),
                dependency: 'birthDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    formData['birthDate'],
                    config.months.find((month) => month.short.toLowerCase() === value.toLowerCase()).value,
                    formData['birthYear']
                  ),
              },
            ],
          },
          {
            flexSpan: 1,
            component: [
              {
                name: 'birthYear',
                label: 'Año',
                id: 'birthYear',
                type: 'tel',
                variant: 'outlined',
                flexSpan: 2,
                inputProps: { mask: config.mask['HN'].year },
                validations: {
                  minLength: 4,
                  maxValue: new Date()?.getFullYear(),
                },
                mask: true,
                dependency: 'birthDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    formData['birthDate'],
                    config.months.find((month) => month.short.toLowerCase() === formData['birthMonth'].toLowerCase())
                      .value,
                    value
                  ),
              },
            ],
          },
        ],
      },
      {
        text: 'Fecha de expiración',
        type: 'label',
        style: { color: '#565656', fontSize: '12px' },
      },
      {
        type: 'formRow',
        components: [
          {
            flexSpan: 1,
            marginRight: '15px',
            component: [
              {
                name: 'expiryDate',
                label: 'Día',
                id: 'expiryDate',
                type: 'tel',
                variant: 'outlined',
                inputProps: { mask: config.mask['HN'].date },
                validations: {
                  minLength: 2,
                  maxValue: 31,
                },
                mask: true,
                dependency: 'expiryDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    value,
                    config.months.find((month) => month.short.toLowerCase() === formData['birthMonth'].toLowerCase())
                      .value,
                    formData['expiryYear']
                  ),
              },
            ],
          },
          {
            flexSpan: 2,
            marginRight: '15px',
            component: [
              {
                name: 'expiryMonth',
                label: 'Mes',
                id: 'expiryMonth',
                type: 'select',
                variant: 'outlined',
                options: config.months.map((month) => month.short),
                dependency: 'expiryDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    formData['expiryDate'],
                    config.months.find((month) => month.short.toLowerCase() === value.toLowerCase()).value,
                    formData['expiryYear']
                  ),
              },
            ],
          },
          {
            flexSpan: 1,
            component: [
              {
                name: 'expiryYear',
                label: 'Año',
                id: 'expiryYear',
                type: 'tel',
                variant: 'outlined',
                flexSpan: 2,
                inputProps: { mask: config.mask['HN'].year },
                validations: {
                  minLength: 4,
                },
                mask: true,
                dependency: 'expiryDate',
                customValidator: (formData, value) =>
                  !isValidDateHN(
                    formData['expiryDate'],
                    config.months.find((month) => month.short.toLowerCase() === formData['expiryMonth'].toLowerCase())
                      .value,
                    value
                  ),
              },
            ],
          },
        ],
      },
      {
        name: 'gender',
        label: 'Género',
        id: 'gender',
        type: 'select',
        variant: 'outlined',
        options: ['', 'Masculino', 'Femenino'],
      },
      {
        name: 'civilStatus',
        label: 'Estado civil',
        id: 'civilStatus',
        type: 'select',
        variant: 'outlined',
        options: ['', 'Casado', 'Divorciado', 'Soltero', 'Unido', 'Viudo'],
      },
      {
        type: 'checkbox',
        value: 'confirmID',
        label: 'Certifico que todos los datos coinciden con el ID escaneado',
        id: 'confirmID',
        inputProps: {  "data-testid": "confirmID" },
      },
      {
        id: "confirmButton",
        type: "button",
        text: "confirmar",
        name: "submit",
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.idScanRear,
      skippedPrevURL: pageURL_GT.idScanFront,
      nextURL: pageURL_GT.billUpload,
      home: pageURL_GT.home,
      redirectURL: pageURL_GT.idNotValid,
    },
    progress: '<span class=progress-status>5/12</span>',
  },
  BillUpload: {
    title: GT_TEXTS.BILL_UPLOAD_TITLE,
    formFields: [
      {
        name: 'billType',
        id: 'billType',
        type: 'dropdown',
        variant: 'outlined',
        flexSpan: 1,
        label: '',
        options: ['Seleccionar proveedor', 'Otro', 'EEGSA', 'Energuate'],
      },
      {
        id: 'billUpload',
        type: 'upload',
        scannerConfig: {
          type: 'upload',
          btnText: 'Cargar recibo o factura de servicio',
          compressionConfig: {
            maxSizeMB: 4,
            maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
            useWebWorker: true,
          },
        },
      },
      {
        type: 'button',
        text: 'continuar',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.idReview,
      nextURL: pageURL_GT.billConfirm,
      home: pageURL_GT.home,
    },
  },
  BillConfirm: {
    title: GT_TEXTS.BILL_CONFIRM_TITLE,
    formFields: [
      {
        name: 'address',
        label: 'Dirección',
        id: 'address',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'id',
        label: 'Número de contador',
        id: 'id',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'pole',
        label: 'Número de poste',
        id: 'pole',
        type: 'text',
        optional: true,
        variant: 'outlined',
      },
      {
        type: 'button',
        text: 'continuar',
        name: 'submit',
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.billUpload,
      nextURL: pageURL_GT.documentUpload,
      jumpToURL: pageURL_GT.products,
      home: pageURL_GT.home,
    },
  },
  DocumentUpload: {
    title: GT_TEXTS.DOCUMENT_UPLOAD_TITLE,
    formFields: [
      {
        type: 'upload',
        scannerConfig: {
          type: 'upload',
          btnText: 'Patente de Comercio/ Acta de Representante',
          fileName: 'patente-acta',
          field: 'patente-acta',
          allowPdf: true,
          customDoc: true,
          compressionConfig: {
            maxSizeMB: 4,
            maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
            useWebWorker: true,
          },
        },
      },
      {
        type: 'upload',
        scannerConfig: {
          type: 'upload',
          btnText: 'RTU',
          fileName: 'rtu',
          field: 'rtu',
          allowPdf: true,
          customDoc: true,
          compressionConfig: {
            maxSizeMB: 4,
            maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
            useWebWorker: true,
          },
        },
      },
      {
        type: 'button',
        name: 'submit',
        text: 'continuar',
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.billConfirm,
      home: pageURL_GT.home,
    },
  },
  NoOffer: {
    title: COMMON_TEXT.NO_OFFER_TITLE,
    content: COMMON_TEXT.NO_OFFER_DESCRIPTION,
    redirect: COMMON_TEXT.CONTINUE_HOME,
    icon: imageURLs.failed,
    redirectURLs: {
      redirectURL: pageURL_GT.clientInformation,
      prevURL: pageURL_GT.documentUpload,
      skippedURL: pageURL_GT.billConfirm,
      home: pageURL_GT.home,
    },
  },
  ProductSelection: {
    title: GT_TEXTS.PRODUCTS_SELECTION_TITLE,
    tabs: ['PAQUETE', 'TV', 'INTERNET'],
    redirectURLs: {
      nextURL: pageURL_GT.addons,
      prevURL: pageURL_GT.documentUpload,
      skippedURL: pageURL_GT.billConfirm,
      home: pageURL_GT.home,
      jumpToURL: pageURL_GT.activation,
    },
    progress: '<span class=progress-status>1/12</span>',
  },
  Addons: {
    title: COMMON_TEXT.HN_ADDONS_TITLE,
    redirectURLs: {
      nextURL: pageURL_GT.checkout,
      prevURL: pageURL_GT.products,
      home: pageURL_GT.home,
    },
    progress: '<span class=progress-status>2/12</span>',
  },
  Checkout: {
    title: texts.CART_EMPTY_TITLE,
    redirectURLs: {
      nextURL: pageURL_GT.contractInfo,
      prevURL: pageURL_GT.addons,
      home: pageURL_GT.home,
      redirectURL: pageURL_GT.products,
      jumpToURL: pageURL_GT.activation,
    },
    progress: '<span class=progress-status>3/12</span>',
  },
  ContractInfo: {
    title: COMMON_TEXT.HN_CONTRACT_INFO_TITLE,
    description: COMMON_TEXT.HN_CONTRACT_INFO_DESCRIPTION,
    formFields: [
        {
            name: 'cliEmail',
            label: 'Correo electrónico',
            id: 'cliEmail',
            type: 'text',
            variant: 'outlined',
            helperText: 'Este correo electrónico será usado para enviar tu factura',
            showInputIcon : true,
            icon : 'edit',
        },
        {
            name: 'cliTelephone',
            label: 'Número celular',
            id: 'cliTelephone',
            type: 'tel',
            variant: 'outlined',
            inputProps: { mask: config.mask['HN'].phoneNumber },
            mask: true,
            validations: {
              minLength: 8,
            },
            showInputIcon : true,
            icon : 'edit',
        },
        {
            name: 'rechargeNumber',
            label: 'Número para recargas',
            id: 'rechargeNumber',
            type: 'tel',
            variant: 'outlined',
            inputProps: { mask: config.mask['HN'].phoneNumber },
            mask: true,
            validations: {
              minLength: 8,
            },
        },
        {
          title: 'Datos de facturación',
          type: 'inputList',
          id: 'billingInfo',
          options: [
              {
                type: 'checkbox',
                value: 'available_name_NIT',
                key: 'available_name_NIT',
                label: 'Nombres y NIT ingresados en paso 1',
                id: 'available_name_NIT',
                inputProps: { 'data-testid': 'available_name_NIT' },
              },
              {
                  name: 'name',
                  label: 'Nombres',
                  id: 'billingName',
                  type: 'text',
                  variant: 'outlined',
              },
              {
                  name: 'nit',
                  label: 'NIT',
                  id: 'billingNIT',
                  type: 'text',
                  variant: 'outlined',
              },
              {
                type: 'checkbox',
                value: 'available_address',
                key: 'available_address',
                label: 'Usar dirección de instalación',
                id: 'available_address',
                inputProps: { 'data-testid': 'available_address' },
              },
              {
                name: 'department',
                label: 'Departamento',
                id: 'billingDepartment',
                type: 'autocomplete',
                getOptionLabel: (option) => option['state'] || option,
                variant: 'outlined',
                noOptionsText: 'Sin opciones',
                dependency: ['billingCity'],
                showCustomIcons: false,
                showSuccessIcon: false,
                'data-testid': 'department',
              },
              {
                name: 'city',
                label: 'Municipio',
                id: 'billingCity',
                type: 'autocomplete',
                getOptionLabel: (option) => option['town'] || option,
                variant: 'outlined',
                noOptionsText: 'Sin opciones',
                showCustomIcons: false,
                showSuccessIcon: false,
                'data-testid': 'billingCity',
              },
              {
                name: 'locality',
                label: 'Zona',
                id: 'billingLocality',
                type: 'tel',
                variant: 'outlined',
                inputProps: { 'data-testid': 'billingLocality' },
              },
              {
                name: 'direction',
                label: 'Dirección legal',
                id: 'billingAddress',
                type: 'text',
                variant: 'outlined',
              },
          ],
        },
        {
            type: 'button',
            text: 'confirmar',
            name: 'submit',
        },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.checkout,
      nextURL: pageURL_GT.verifyPhone,
      home: pageURL_GT.home,
    },
    progress: '<span class=progress-status>6/12</span>',
  },
  VerifyPhone: {
    title: (value) => `${COMMON_TEXT.HN_VERIFY_PHONE_TITLE} al ${value.cliTelephone}`,
    inputConfig: {
      name: 'otp',
      label: 'Código de verificación',
      id: 'otp',
      type: 'tel',
      variant: 'outlined',
      inputProps: { mask: config.mask['HN'].otp },
      validations: {
        minLength: 6,
      },
    },
    buttonConfigPrimary: {
      type: 'button',
      text: 'VERIFICAR',
      name: 'submit',
    },
    skipButton: {
      text: 'OMITIR',
      color: 'transparent',
      textColor: appColors.palette.teal,
      variant: 'rounded',
      marginTop: 20,
    },
    buttonConfig: {
      color: 'transparent',
      textColor: appColors.secondaryCyan,
      variant: 'rounded',
      text: 'Solicita uno nuevo',
      textTransform: true,
      style: { fontSize: '16px' },
    },
    labelConfig: {
      text: '¿No has recibido el código?',
      fontColor: appColors.textColorGrey,
      style: { marginTop: '30px' },
    },
    progress: '<span class=progress-status>7/12</span>',
    redirectURLs: {
      prevURL: pageURL_GT.contractInfo,
      nextURL: pageURL_GT.verifyEmail,
      home: pageURL_GT.home,
    },
    type: 'phone',
    otpChannel: 'SMS'
  },
  VerifyEmail: {
    title: (value) => `${COMMON_TEXT.HN_VERIFY_PHONE_TITLE} al ${value.cliEmail}`,
    inputConfig: {
      name: 'otp',
      label: 'Código de verificación',
      id: 'otp',
      type: 'tel',
      variant: 'outlined',
      inputProps: { mask: config.mask['HN'].otp },
      validations: {
        minLength: 6,
      },
    },
    verifyButton: {
      text: 'VERIFICAR',
      variant: 'rounded',
    },
    skipButton: {
      text: 'OMITIR',
      color: 'transparent',
      textColor: appColors.palette.teal,
      variant: 'rounded',
      marginTop: 20,
    },
    buttonConfig: {
      color: 'transparent',
      textColor: appColors.secondaryCyan,
      variant: 'rounded',
      text: 'Solicita uno nuevo',
      textTransform: true,
      style: { fontSize: '16px' },
    },
    labelConfig: {
      text: '¿No has recibido el código?',
      fontColor: appColors.textColorGrey,
      style: { marginTop: '30px' },
    },
    progress: '<span class=progress-status>8/12</span>',
    redirectURLs: {
      prevURL: pageURL_GT.verifyPhone,
      nextURL: pageURL_GT.termsConditions,
      home: pageURL_GT.home,
    },
    type: 'email',
    otpChannel: 'email',
  },
  TermsAndConditions: {
    title: 'Acuerdo de servicios',
    redirectURLs: {
      prevURL: pageURL_GT.verifyEmail,
      nextURL: pageURL_GT.contractSignatureInfo,
      home: pageURL_GT.home,
    },
  },
  ContractSignatureInfo: {
    title: 'Firma de autorizaciones',
    formFields: [
      {
        type: 'cameraInstruction',
        instructions: [{ src: '/images/contract-sign.svg' }],
      },
      {
        type: 'label',
        text: 'Gira tu celular',
        style: { fontSize: '18px' },
        fontColor: appColors.textColorBlue,
      },
      {
        type: 'label',
        text: 'Usa tu dedo para firmar sobre la línea.',
        fontColor: appColors.textColorGrey,
      },
      {
        type: 'button',
        variant: 'rounded',
        text: 'comenzar',
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.termsConditions,
      nextURL: pageURL_GT.signatureClient,
      home: pageURL_GT.home,
    },
  },
  SignatureClient: {
    fileName: 'contract_signature',
    redirectURLs: {
      prevURL: pageURL_GT.contractSignatureInfo,
      nextURL: pageURL_GT.paymentReceipt,
    },
  },
  PaymentReceipt: {
    title: GT_TEXTS.PAYMENT_RECEIPT_TITLE,
    formFields: [
      {
        name: 'voucher',
        label: 'Numero de comprobante',
        id: 'voucher',
        type: 'text',
        variant: 'outlined',
        inputProps: {
          mask: config.mask['GT'].paymentReceiptNumber,
        },
      },
      {
        id: 'receipt-doc',
        type: 'upload',
        scannerConfig: {
          type: 'upload',
          field: 'payment_receipt',
          fileName: 'payment_receipt',
          btnText: 'Comprobante de pago',
          customDoc: true,
          compressionConfig: {
            maxSizeMB: 4,
            maxWidthOrHeight: isIOSDevice() ? 1024 : 2520,
            useWebWorker: true,
          },
        },
      },
    ],
    redirectURLs: {
      prevURL: pageURL_GT.signatureClient,
      nextURL: pageURL_GT.scheduleVisit,
      home: pageURL_GT.home,
    },
  },
  ScheduleVisit: {
    title: GT_TEXTS.SCHEDULE_VISIT_TITLE,
    formFields: [
      {
        type: 'radio',
        options: [
          { value: 'AM', text: 'AM' },
          { value: 'PM', text: 'PM' },
        ],
        name: 'time',
        id: 'time',
        dependency: ['installationDate'],
      },
      {
        name: 'installationDate',
        id: 'installationDate',
        type: 'date',
        variant: 'outlined',
        placeholder: 'DD/MM/YYYY',
        disablePast: false,
        label: 'Seleccionar fecha',
      },
      {
        name: 'cliTelephone',
        label: 'Teléfono adicional',
        id: 'telephoneAdditional',
        type: 'tel',
        variant: 'outlined',
        helperText: 'Para confirmar la instalación',
        optional: true,
        inputProps: { mask: config.mask['HN'].phoneNumber },
        mask: true,
      },
      {
        name: 'actComments',
        label: 'Mensaje para el instalador',
        id: 'actComments',
        type: 'textarea',
        variant: 'outlined',
        optional: true,
        multiline: true,
        rows: 6,
      },
      {
        type: 'button',
        text: 'confirmar',
        name: 'submit',
      },
    ],
    optionalParams: ['telephoneAdditional', 'actComments'],
    redirectURLs: {
      prevURL: pageURL_GT.paymentReceipt,
      nextURL: pageURL_GT.activation,
      home: pageURL_GT.home,
      jumpToURL: pageURL_GT.activation,
      changePlan: {
        prevURL: pageURL_GT.changePlan,
        nextURL: pageURL_GT.confirmOrder,
      },
    },
    calendarButtonProps: {
      cancel: 'CANCELAR',
      confirm: 'GUARDAR',
    },
  },
  Activation: {
    title: GT_TEXTS.CONFIRM_ORDER,
    redirectURLs: {
      prevURL: pageURL_GT.scheduleVisit,
      redirectURL: pageURL_GT.products,
      home: pageURL_GT.home,
      cancel: pageURL_GT.clientInformation,
      success: pageURL_GT.orderSuccess,
      failed: pageURL_GT.orderFailed,
    },
  },
  OrderSuccess: {
    title: GT_TEXTS.ORDER_SUCCESS_TITLE,
    redirect: COMMON_TEXT.HN_ORDER_SUCCESS_BUTTON,
    icon: imageURLs.confirmation,
    redirectURLs: {
      home: pageURL_GT.home,
      redirectURL: pageURL_GT.clientInformation,
    },
  },
  OrderFailed: {
    title: GT_TEXTS.ORDER_FAILED_TITLE,
    redirect: GT_TEXTS.ORDER_FAILED_BUTTON,
    icon: imageURLs.failed,
    redirectURLs: {
      home: pageURL_GT.home,
      redirectURL: pageURL_GT.clientInformation,
    },
  },
  SalesReport: {
    title: texts.SALES_REPORT,
    sidebarMenu: [
      {
        title: GT_TEXTS.HOME_NEW_CLIENT,
        url: pageURL_GT.clientInformation,
        iconType: '',
      },
      {
        title: GT_TEXTS.HOME_SALES_REPORT,
        url: pageURL_GT.salesReport,
        iconType: '',
      },
      {
        title: GT_TEXTS.HOME_LOGOUT,
        url: '',
        iconType: '',
      },
    ],
    redirectURL: {
      home: pageURL_GT.home,
    },
  },
  ModifyExistingService: {
    title: GT_TEXTS.MODIFY_EXISTING_SERVICE_TITLE,
    redirectURLs: {
      prevURL: pageURL_GT.existingServices,
      addonsURL: pageURL_GT.updateAddons,
      plansURL: pageURL_GT.changePlan,
      home: pageURL_GT.home,
    },
  },
  UpdateAddons: {
    title: GT_TEXTS.UPDATE_ADDONS_TITLE,
    redirectURLs: {
      prevURL: pageURL_GT.existingServicesModify,
      nextURL: pageURL_GT.confirmOrder,
      home: pageURL_GT.home,
    },
  },
  ConfirmOrder: {
    title: GT_TEXTS.CONFIRM_ORDER,
    redirectURLs: {
      prevURL: pageURL_GT.updateAddons,
      redirectURL: {
        updateAddons: pageURL_GT.updateAddons,
        changePlan: pageURL_GT.changePlan,
      },
      home: pageURL_GT.home,
      schedule: pageURL_GT.scheduleVisit,
      cancel: pageURL_GT.home,
      success: pageURL_GT.updatedOrderSuccess,
      failed: pageURL_GT.orderFailed,
    },
  },
  ChangePlan: {
    title: GT_TEXTS.PRODUCTS_SELECTION_TITLE,
    tabs: ['PAQUETE', 'TV', 'INTERNET'],
    redirectURLs: {
      nextURL: pageURL_GT.scheduleVisit,
      prevURL: pageURL_GT.existingServicesModify,
      home: pageURL_GT.home,
      jumpToURL: pageURL_GT.confirmOrder,
    },
  },
};
