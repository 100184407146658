import { config as formConfig, CO_TEXTS, pageURL_CO, imageURLs, REGEX_CONSTANTS } from 'constants/index';
import { replaceSpecialChars, removeWhiteSpace } from 'utils/utils';
import { stepperTexts } from 'constants/texts';
import { track } from 'utils/segmenTracker';
import { appConfig } from './appConfig';
import {
  INELIGIBILITY_SCREEN_LOADED,
  EXTERNAL_LINK_CLICKED,
  INSTRUCTIONS_LOADED,
  CLIENT_DETAILS_LOADED,
  CLIENT_DETAILS_SUBMITTED,
  ORDER_COMPLETED,
  ADDRESS_FORM_LOADED,
} from 'constants/segmentEvents';

const date = (start, end) => {
  let arr = [''];
  let startNumber = start;
  while (startNumber < end) {
    const displayNumber = startNumber < 10 ? `0${startNumber}` : `${startNumber}`;
    arr.push(displayNumber);
    startNumber++;
  }
  return arr;
};

export const UIConfig = (pageName) => {
  const currentYear = new Date()?.getFullYear() + 100;
  const config = {
    leadToCall: {
      formFields: [
        {
          name: 'cliFirstName',
          label: 'Nombre y Apellido',
          id: 'cliFirstName',
          type: 'text',
          variant: 'outlined',
          helperText: (value, error) => {
            if (!error) return '';
            if (!value) return 'El campo es obligatorio.';
          },
        },
        {
          name: 'cliTelephone',
          label: 'Número Móvil',
          id: 'cliTelephone',
          type: 'text',
          variant: 'outlined',
          inputProps: { mask: formConfig.mask['CO'].phoneNumber },
          mask: true,
          helperText: (value, error) => {
            if (!error) return '';
            if (!value) return 'El campo es obligatorio.';
            const formattedValue = removeWhiteSpace(replaceSpecialChars(value));
            if (formattedValue[0] !== '3' || formattedValue.length < 10) return 'El Número ingresado no es valido.';
          },
        },
        {
          type: 'linkWithLabel',
          label: CO_TEXTS.ACCEPT_CONTENT,
          link: CO_TEXTS.TERMS_CONDITIONS,
          externalLink: CO_TEXTS.TERMS_CONDITIONS_LINK,
        },
        {
          type: 'button',
          text: 'TE LLAMAMOS',
          name: 'submit',
          alignSelf: 'center',
          variant: 'secondary',
          style: {
            backgroundColor: 'white',
            border: '1px solid #00C8FF',
            borderRadius: '18px',
            color: '#00c8ff',
            minWidth: '136px',
          },
        },
      ],
      redirectURLs: {
        nextURL: pageURL_CO.leadToCallConfirmation,
        jumpToURL: pageURL_CO.inputAddress,
      },
      zendeskLocationsModel: {
        name: 'Cliente Sin Nombre',
        email: null,
        subject: 'Estoy interesado en un plan Hogar',
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        acceptText: 'Acepto las políticas de privacidad y tratamiento de datos personales.',
        tags: ['b2c_formulario_ventas', 'homeactivator', 'ha_te-llamamos'],
        brand_id: 4987608126739,
        ticket_form_id: 7753182686611,
        custom_fields: [
          {
            id: '1500009061601',
            value: 'b2c_tiposerv_fijo',
          },
          {
            id: '1500010742881',
            value: 'b2c_solicitudfijo_ventas',
          },
          {
            id: '4926440549907',
            value: '',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742761',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1500010803662',
            value: '',
          },
          {
            id: '4926471030675',
            value: '',
          },
          {
            id: '1900002482025',
            value: 'b2c_prod_hogar_solicitudservicio',
          },
          {
            id: '1900002482065',
            value: '',
          },
          {
            id: '1500010836822',
            value: 'PENDIENTE',
          },
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_datosinsuficientes',
          },
        ],
      },
      stepperProps: [
        { text: stepperTexts.yourAddress, active: true },
        { text: stepperTexts.yourPlan, disabled: true },
        { text: stepperTexts.yourData, disabled: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      trackEvents: {
        onMount: () => track(CLIENT_DETAILS_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'Quiero mi plan ahora',
            link_url: `${appConfig.apiURL}/direccion-detallada`,
          }),
        onPostpagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Ver Pospago', link_url: CO_TEXTS.POSTPAID_EXTERNAL }),
        onPrepagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Prepago', link_url: CO_TEXTS.PREPAID_EXTERNAL }),
        onSubmitClick: () => track(CLIENT_DETAILS_SUBMITTED),
      },
    },
    leadCallConfirmation: {
      externalPrepaid: CO_TEXTS.PREPAID_EXTERNAL,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.leadToCall, jumpToURL: pageURL_CO.inputAddress },
      trackEvents: {
        onMount: () => track(INSTRUCTIONS_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'Quiero mi plan ahora',
            link_url: `${appConfig.apiURL}/direccion-detallada`,
          }),
        onPostpagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Ver Pospago', link_url: CO_TEXTS.POSTPAID_EXTERNAL }),
        onPrepagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Prepago', link_url: CO_TEXTS.PREPAID_EXTERNAL }),
      },
    },
    inputAddress: {
      formFields: [
        {
          name: 'cliTelephone',
          label: 'Número Móvil',
          id: 'cliTelephone',
          type: 'text',
          variant: 'outlined',
          inputProps: { mask: formConfig.mask['CO'].phoneNumber },
          mask: true,
          helperText: (value, error) => {
            if (!error) return '';
            if (!value) return 'El campo es obligatorio.';
            const formattedValue = removeWhiteSpace(replaceSpecialChars(value));
            if (formattedValue[0] !== '3' || formattedValue.length < 10) return 'El Número ingresado no es valido.';
          },
        },
        {
          name: 'department',
          label: 'Departamento',
          id: 'department',
          type: 'autocomplete',
          getOptionLabel: (option) => option['provinceName'] || '',
          variant: 'outlined',
          noOptionsText: 'Sin opciones',
          dependency: ['suburb', 'city'],
          showCustomIcons: true,
          showSuccessIcon: false,
          helperText: (value, error) => {
            if (value.provinceName) return '';
            else if (error && !value.provinceName) return 'El campo es obligatorio.';
          },
        },
        {
          name: 'Ciudad',
          label: 'Municipio',
          id: 'city',
          type: 'autocomplete',
          getOptionLabel: (option) => option['displayName'] || '',
          variant: 'outlined',
          noOptionsText: 'Sin opciones',
          dependency: ['suburb'],
          showCustomIcons: true,
          showSuccessIcon: false,
          helperText: (value, error) => {
            if (value.displayName) return '';
            else if (error && !value.displayName) return 'El campo es obligatorio.';
          },
        },
        {
          type: 'formRow',
          components: [
            {
              width: '50%',
              marginRight: '15px',
              component: [
                {
                  name: 'Street',
                  label: 'Tipo de vía',
                  id: 'streetType',
                  type: 'autocomplete',
                  options: ['Calle', 'Carrera', 'Avenida', 'Avenida Carrera', 'Avenida Calle', 'Circular', 'Circunvalar', 'Diagonal', 'Manzana', 'Transversal', 'Via'].map((n) => ({
                    name: n,
                    displayName: n,
                  })),
                  getOptionLabel: (option) => option.displayName || '',
                  variant: 'outlined',
                  noOptionsText: 'Sin opciones',
                  showCustomIcons: true,
                  showSuccessIcon: false,
                  helperText: (value, error) => {
                    if (value) return '';
                    else if (error && !value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
            {
              width: '50%',
              component: [
                {
                  name: 'Numero',
                  label: 'Nombre, No/Letra',
                  id: 'streetNumber',
                  type: 'text',
                  variant: 'outlined',
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formRow',
          components: [
            {
              width: '50%',
              marginRight: '15px',
              component: [
                {
                  name: 'Street #',
                  label: '# Número/Letra',
                  id: 'streetHash',
                  type: 'text',
                  variant: 'outlined',
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
            {
              width: '50%',
              component: [
                {
                  name: 'Street -',
                  label: '- Número/Letra',
                  id: 'streetDash',
                  type: 'text',
                  variant: 'outlined',
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formRow',
          components: [
            {
              width: '50%',
              marginRight: '15px',
              component: [
                {
                  name: 'tower',
                  label: 'Torre',
                  id: 'tower',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 3,
                 },
                },
              ],
            },
            {
              width: '50%',
              component: [
                {
                  name: 'apartment',
                  label: 'Apartamento',
                  id: 'apartment',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 5,
                    mask: formConfig.mask['CO'].apartment
                 },
                },
              ],
            },
          ],
        },
        {
          name: 'directionInfo',
          label: 'Información adicional a tu dirección (omitir palabra PISO)',
          id: 'aptNo',
          type: 'textarea',
          variant: 'outlined',
          multiline: true,
          optional: true,
          inputProps: {
            maxLength: 50,
          },
        },
        {
          type: 'linkWithLabel',
          label: CO_TEXTS.ACCEPT_CONTENT_INPUT_ADDRESS,
          link: CO_TEXTS.TERMS_CONDITIONS,
          externalLink: 'https://assets.tigocloud.net/j1bxozgharz5/1QHObunYvEAeGgyaHqDEpN/66c4945c51ad3cf928977d8bbe33a6f6/Legales_Tigo.pdf',
          id: 'linkWithLabel',
        },
        {
          type: 'buttonLoader',
          text: 'VER COBERTURA',
          name: 'submit',
          alignSelf: 'center',
          variant: 'secondary',
          style: {
            borderRadius: '18px',
            minWidth: '146px',
          },
          id: 'button',
        },
        {
          type: 'custom-elm',
          id: 'custom-elm',
        },
      ],
      formFieldsDesktop: [
        {
          type: 'formRow',
          components: [
            {
              width: '50%',
              marginRight: '15px',
              component: [
                {
                  name: 'department',
                  label: 'Departamento',
                  id: 'department',
                  type: 'autocomplete',
                  getOptionLabel: (option) => option['provinceName'] || '',
                  variant: 'outlined',
                  noOptionsText: 'Sin opciones',
                  dependency: ['suburb', 'city'],
                  showCustomIcons: true,
                  showSuccessIcon: false,
                  helperText: (value, error) => {
                    if (value.provinceName) return '';
                    else if (error && !value.provinceName) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
            {
              width: '50%',
              component: [
                {
                  name: 'Ciudad',
                  label: 'Municipio',
                  id: 'city',
                  type: 'autocomplete',
                  getOptionLabel: (option) => option['displayName'] || '',
                  variant: 'outlined',
                  noOptionsText: 'Sin opciones',
                  dependency: ['suburb'],
                  showCustomIcons: true,
                  showSuccessIcon: false,
                  helperText: (value, error) => {
                    if (value.displayName) return '';
                    else if (error && !value.displayName) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'directionInfo',
          label: 'Información adicional a tu dirección (omitir palabra PISO)',
          id: 'aptNo',
          type: 'textarea',
          rows: 5,
          variant: 'outlined',
          multiline: true,
          optional: true,
          inputProps: {
            maxLength: 50,
          },
        },
      ],
      stepperProps: [
        { text: stepperTexts.yourAddress, active: true },
        { text: stepperTexts.yourPlan, disabled: true },
        { text: stepperTexts.yourData, disabled: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      titleText: 'Escribe tu dirección y teléfono para comprobar',
      titleTextLink: 'tus ofertas TIGO disponibles.',
      errorIcon: imageURLs.failed,
      zendeskLocationsModel: {
        name: 'Cliente Sin Nombre',
        email: null,
        subject: 'Estoy interesado en un plan Hogar',
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        acceptText: 'Acepto las políticas de privacidad y tratamiento de datos personales.',
        brand_id: 4987608126739,
        ticket_form_id: 7753182686611,
        custom_fields: [
          {
            id: '1500009061601',
            value: 'b2c_tiposerv_fijo',
          },
          {
            id: '1500010742881',
            value: 'b2c_solicitudfijo_ventas',
          },
          {
            id: '4926440549907',
            value: 'Cliente Sin Nombre',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742761',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1500010803662',
            value: '',
          },
          {
            id: '4926471030675',
            value: '',
          },
          {
            id: '1900002482025',
            value: 'b2c_prod_hogar_solicitudservicio',
          },
          {
            id: '1900002482065',
            value: '',
          },
          {
            id: '1500010836822',
            value: 'PENDIENTE',
          },
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_datosinsuficientes',
          },
        ],
      },
      redirectURLs: {
        nextURL: pageURL_CO.hfcFailed,
        jumpToURL: pageURL_CO.noCoverage,
        noAddress: pageURL_CO.noAddress,
        packages: pageURL_CO.packages,
      },
    },
    hfcFailed: {
      externalPrepaid: CO_TEXTS.PREPAID_EXTERNAL,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoTitleText: 'Estamos validando la tecnología apropiada para tu servicio.',
      infoIcon: imageURLs.hfc,
      infoTitleSubText: 'Espera nuestra llamada. Un asesor se comunicará contigo.',
      // bannerImage: imageURLs.hfcBanner,
      // mobileBanner: imageURLs.hfcBannerMobile,
      footerText: '¡Siempre 2X1 en tus paquetes. Con voz Ilimitada Nacional y datos, pasándote a TIGO!.',
      additionalText: 'PÁSATE YA',
      directionText: 'CAMBIAR DIRECCIÓN',
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_diferente-hfc'],
      },
      zendeskUpdateCall: true,
      endFlow: true,
      trackEvents: {
        onMount: () => track(INELIGIBILITY_SCREEN_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'cambiar direccion',
            link_url: `${appConfig.apiURL}/direccion-detallada`,
          }),
        onPostpagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Ver Pospago', link_url: CO_TEXTS.POSTPAID_EXTERNAL }),
        onPrepagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Prepago', link_url: CO_TEXTS.PREPAID_EXTERNAL }),
      },
    },
    noCoverage: {
      externalPrepaid: CO_TEXTS.PREPAID_EXTERNAL,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoTitleText: 'Hemos recibido tu solicitud',
      infoIcon: imageURLs.hfc,
      infoTitleSubText: 'Estamos verificando la cobertura, uno de nuestros asesores se comunicará contigo para brindarte más información.',
      infoTitleSubContent: 'Si necesitas AYUDA llámanos gratis al 018000 415 198',
      // bannerImage: imageURLs.hfcBanner,
      // mobileBanner: imageURLs.hfcBannerMobile,
      footerText: '¡Siempre 2X1 en tus paquetes. Con voz Ilimitada Nacional y datos, pasándote a TIGO!.',
      additionalText: 'PÁSATE YA',
      directionText: 'CAMBIAR DIRECCIÓN',
      trackEvents: {
        onMount: () => track(INELIGIBILITY_SCREEN_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'cambiar direccion',
            link_url: `${appConfig.apiURL}/direccion-detallada`,
          }),
        onPostpagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Ver Pospago', link_url: CO_TEXTS.POSTPAID_EXTERNAL }),
        onPrepagoClick: () => track(EXTERNAL_LINK_CLICKED, { flag: 'Prepago', link_url: CO_TEXTS.PREPAID_EXTERNAL }),
      },
    },
    PersonalInfo: {
      stepperProps: [
        { text: stepperTexts.yourAddress, completed: true },
        { text: stepperTexts.yourPlan, completed: true },
        { text: stepperTexts.yourData, active: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      zendeskRegisterModel: {
        commentBody: 'Hola, estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        acceptText: 'Acepto las políticas de privacidad y tratamiento de datos personales.',
        tags: ['b2c_formulario_ventas', 'homeactivator', 'ha_informacion-personal'],
        custom_fields: [
          {
            id: '4926440549907',
            value: '',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1900002482065',
            value: '',
          },
        ],
      },

      formFields: [
        {
          type: 'label',
          text: 'Completa los datos de tu documento:',
          style: { margin: '15px 0px', color: '#1D1D1F' },
        },
        {
          type: 'formRow',
          components: [
            {
              flexSpan: 1,
              className: 'form-first-name',
              component: [
                {
                  name: 'cliFirstName',
                  label: 'Nombres',
                  id: 'cliFirstName',
                  type: 'text',
                  variant: 'outlined',
                  inputProps: { mask: formConfig.mask['CO'].name },
                  mask: true,
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                    if (!REGEX_CONSTANTS.NAME_REGEX.test(value)) return 'El tipo de dato permitido es texto';
                  },
                },
              ],
            },
            {
              flexSpan: 1,
              className: 'form-last-name',
              component: [
                {
                  name: 'cliLastName',
                  label: 'Apellidos',
                  id: 'cliLastName',
                  type: 'text',
                  variant: 'outlined',
                  validations: {
                    maxLength: 30,
                  },
                  inputProps: { mask: formConfig.mask['CO'].name },
                  mask: true,
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                    if (!REGEX_CONSTANTS.NAME_REGEX.test(value)) return 'El tipo de dato permitido es texto';
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formRow',
          components: [
            {
              flexSpan: 1,
              className: 'form-email',
              component: [
                {
                  name: 'cliEmail',
                  label: 'Correo electrónico',
                  id: 'cliEmail',
                  type: 'text',
                  variant: 'outlined',
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                    if (!REGEX_CONSTANTS.EMAIL_REGEX.test(value)) return 'El tipo de dato ingresado No es valido.';
                  },
                },
              ],
            },
            {
              flexSpan: 1,
              marginRight: '15px',
              component: [
                {
                  name: 'documentType',
                  label: 'Tipo',
                  id: 'documentType',
                  type: 'select',
                  variant: 'outlined',
                  options: ['CC', 'CE'],
                },
              ],
            },
            {
              flexSpan: 3,
              component: [
                {
                  name: 'documentNumber',
                  label: 'Número de documento',
                  id: 'documentNumber',
                  type: 'text',
                  variant: 'outlined',
                  inputProps: { mask: formConfig.mask['CO'].document },
                  mask: true,
                  helperText: (value, error) => {
                    if (!error) return '';
                    if (!value) return 'El campo es obligatorio.';
                    if (!REGEX_CONSTANTS.PHONE_NUMBER.test(value)) return 'El tipo de dato permitido es numérico';
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'label',
          text: 'Fecha de expedición de tu documento',
          style: { margin: '15px 0px', color: '#1D1D1F' },
        },
        {
          type: 'formRow',
          className: 'form-expiry',
          components: [
            {
              flexSpan: 1,
              marginRight: '15px',
              component: [
                {
                  name: 'expiryDate',
                  label: 'Día',
                  id: 'expiryDate',
                  type: 'select',
                  options: date(1, 32),
                  variant: 'outlined',
                  helperText: (value, error) => {
                    if (value) return '';
                    else if (error && !value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
            {
              flexSpan: 1,
              marginRight: '15px',
              component: [
                {
                  name: 'expiryMonth',
                  label: 'Mes',
                  id: 'expiryMonth',
                  type: 'select',
                  variant: 'outlined',
                  options: formConfig.months.map((month) => month.label),
                  helperText: (value, error) => {
                    if (value) return '';
                    else if (error && !value) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
            {
              flexSpan: 1,
              component: [
                {
                  name: 'expiryYear',
                  label: 'Año',
                  id: 'expiryYear',
                  type: 'select',
                  options: date(1950, currentYear),
                  variant: 'outlined',
                  noOptionsText: 'Sin opciones',
                  getOptionLabel: (option) => option || '',
                  helperText: (value, error) => {
                    if (value.displayName) return '';
                    else if (error && !value.displayName) return 'El campo es obligatorio.';
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'formRow',
          components: [
            {
              flexSpan: 1,
              className: 'form-telephone',
              component: [
                {
                  name: 'cliTelephone',
                  label: '',
                  id: 'cliTelephone',
                  type: 'hidden',
                  variant: 'outlined',
                  inputProps: { mask: formConfig.mask['CO'].phoneNumber },
                  mask: true,
                  disabled: true,
                },
              ],
            },
            {
              flexSpan: 1,
              className: 'form-address',
              component: [
                {
                  name: 'address',
                  label: '',
                  id: 'address',
                  type: 'hidden',
                  variant: 'outlined',
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          type: 'button',
          text: 'CONTINUAR',
          name: 'submit',
          variant: 'secondary',
          id: 'button',
          style: {
            display: 'none',
          },
          className: 'btn-centered',
        },
      ],
      redirectURLs: {
        nextURL: pageURL_CO.orderSuccess,
      },
      termsConditions: {
        preText: 'Al presionar',
        boldText: 'CONTINUAR',
        postText: 'estás autorizando la consulta en centrales de riesgo y la validación de identidad con métodos propios de Tigo y/o a través de terceros',
        linktext: 'Ver detalles autorización.',
        link: '/1587141265636R844.pdf',
      },
    },

    availablePackages: {
      stepperProps: [
        { text: stepperTexts.yourAddress, completed: true },
        { text: stepperTexts.yourPlan, active: true },
        { text: stepperTexts.yourData, disabled: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      redirectURLs: {
        nextURL: pageURL_CO.personalInfo,
      },

      zendeskRegisterModel: {
        commentBody: 'Hola, estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        tags: ['b2c_formulario_ventas', 'homeactivator', 'ha_seleccion-oferta'],
        custom_fields: [
          {
            id: '4926440549907',
            value: '',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1900002482065',
            value: '',
          },
        ],
      },
    },
    orderSuccess: {
      externalPrepaid: CO_TEXTS.NEWLINE,
      externalPostpaid: CO_TEXTS.PORTABILITY_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoTitleTextWeb: '¡Hola :User!.',
      infoTitleTextMob: ':User, hemos recibido tu solicitud.',
      infoIcon: imageURLs.leadStatusSuccess,
      infoTitleSubTextWeb: 'Un asesor se comunicará contigo para confirmar tu pedido.',
      infoTitleSubTextMob: 'Un asesor se comunicará contigo para confirmar tu pedido.',
      // bannerImage: imageURLs.leadConfirmBannerDesktop,
      // mobileBanner: imageURLs.leadConfirmBanner,
      footerText: 'Agregar un Plan Pospago y mantenerme siempre conectado.',
      footerTextMob: 'Agrega un Plan Pospago y mantente siempre conectado.',
      footerTextTitle: '¿Quieres datos y minutos ilimitados?',
      additionalText: 'PÁSATE YA',
      directionText: 'CAMBIAR DIRECCIÓN',
      zendeskSuccessModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        custom_fields: [
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_gestion',
          },
        ],
      },
      zendeskUpdateCall: true,
      trackEvents: {
        onMount: () =>
          track(ORDER_COMPLETED, {
            page_name: 'orden-recibida',
          }),
        onPostpagoClick: (url) => track(EXTERNAL_LINK_CLICKED, { flag: 'pasar mi numero a tigo', link_url: url }),
        onPrepagoClick: (url) => track(EXTERNAL_LINK_CLICKED, { flag: 'compra una linea nueva', link_url: url }),
      },
    },
    inputSingleLine: {
      formFields: [
        {
          name: 'cliTelephone',
          label: 'Número Celular',
          id: 'cliTelephone',
          type: 'text',
          variant: 'outlined',
          inputProps: { mask: formConfig.mask['CO'].phoneNumber },
          mask: true,
          helperText: (value, error) => {
            if (!error) return '';
            if (!value) return 'El campo es obligatorio.';
            const formattedValue = removeWhiteSpace(replaceSpecialChars(value));
            if (formattedValue[0] !== '3' || formattedValue.length < 10) return 'El Número ingresado no es valido.';
          },
        },
        {
          name: 'street',
          label: 'Tu dirección',
          id: 'street',
          type: 'autocomplete',
          getOptionLabel: (option) => option || '',
          variant: 'outlined',
          noOptionsText: 'Sin opciones',
          showCustomIcons: true,
          showSuccessIcon: false,
          helperText: (value, error) => {
            if (value) return '';
            else if (error && !value) return 'El campo es obligatorio.';
          },
          locationAutoComplete: true,
          clearOnBlur: false,
        },
        {
          type: 'formRow',
          components: [
            {
              width: '50%',
              marginRight: '15px',
              component: [
                {
                  name: 'tower',
                  label: 'Torre',
                  id: 'tower',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 3,
                 },
                },
              ],
            },
            {
              width: '50%',
              component: [
                {
                  name: 'apartment',
                  label: 'Apartamento',
                  id: 'apartment',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 5,
                    mask: formConfig.mask['CO'].apartment
                 },
                },
              ],
            },
          ],
        },
        {
          name: 'directionInfo',
          label: 'Información adicional a tu dirección (omitir palabra PISO)',
          id: 'aptNo',
          type: 'text',
          variant: 'outlined',
          optional: true,
          inputProps: {
            maxLength: 50,
          },
        },
        {
          type: 'linkWithLabel',
          label: CO_TEXTS.ACCEPT_CONTENT_INPUT_ADDRESS,
          link: CO_TEXTS.TERMS_CONDITIONS,
          externalLink: 'https://assets.tigocloud.net/j1bxozgharz5/1QHObunYvEAeGgyaHqDEpN/66c4945c51ad3cf928977d8bbe33a6f6/Legales_Tigo.pdf',
          id: 'linkWithLabel',
        },
        {
          type: 'buttonLoader',
          text: 'VER COBERTURA',
          name: 'submit',
          alignSelf: 'center',
          variant: 'secondary',
          style: {
            borderRadius: '18px',
            minWidth: '146px',
          },
          id: 'button',
        },
      ],
      stepperProps: [
        { text: stepperTexts.yourAddress, active: true },
        { text: stepperTexts.yourPlan, disabled: true },
        { text: stepperTexts.yourData, disabled: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      titleText: 'Escribe tu dirección y teléfono para comprobar',
      titleTextLink: 'tus ofertas TIGO disponibles.',
      redirectURLs: {
        nextURL: pageURL_CO.hfcFailed,
        jumpToURL: pageURL_CO.inputMap,
        noAddress: pageURL_CO.noAddress,
        packages: pageURL_CO.packages,
        noCoverage: pageURL_CO.noCoverage,
      },
      zendeskLocationsModel: {
        name: 'Cliente Sin Nombre',
        email: null,
        subject: 'Estoy interesado en un plan Hogar',
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        acceptText: 'Acepto las políticas de privacidad y tratamiento de datos personales.',
        brand_id: 4987608126739,
        ticket_form_id: 7753182686611,
        custom_fields: [
          {
            id: '1500009061601',
            value: 'b2c_tiposerv_fijo',
          },
          {
            id: '1500010742881',
            value: 'b2c_solicitudfijo_ventas',
          },
          {
            id: '4926440549907',
            value: 'Cliente Sin Nombre',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742761',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1500010803662',
            value: '',
          },
          {
            id: '4926471030675',
            value: '',
          },
          {
            id: '1900002482025',
            value: 'b2c_prod_hogar_solicitudservicio',
          },
          {
            id: '1900002482065',
            value: '',
          },
          {
            id: '1500010836822',
            value: 'PENDIENTE',
          },
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_datosinsuficientes',
          },
        ],
      },
      trackEvents: {
        onMount: () => track(ADDRESS_FORM_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'Direccion paso a paso - single address',
            link_url: `${appConfig.apiURL}/direccion-detallada?utm_source=landing&utm_medium=ref&utm_campaign=dig-ha-suscripcion-home&utm_content=auto-g-transacciones&utm_term=click-boton-paso-a-paso--single-address`,
          }),
        onMapClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'Direccion por Mapa - single address',
            link_url: `${appConfig.apiURL}/direccion-mapa?utm_source=landing&utm_medium=ref&utm_campaign=dig-ha-suscripcion-home&utm_content=auto-g-transacciones&utm_term=click-boton-ir-al-mapa--single-address`,
          }),
      },
    },
    inputMap: {
      stepperProps: [
        { text: stepperTexts.yourAddress, active: true },
        { text: stepperTexts.yourPlan, disabled: true },
        { text: stepperTexts.yourData, disabled: true },
        { text: stepperTexts.yourOrder, disabled: true },
      ],
      formFields: [
        {
          type: 'formRow',
          components: [
            {
              flexSpan: 1,
              className: 'form-address',
              component: [
                {
                  name: 'cliTelephone',
                  label: 'Número Celular',
                  id: 'cliTelephone',
                  type: 'text',
                  variant: 'outlined',
                  inputProps: { mask: formConfig.mask['CO'].phoneNumber },
                  mask: true,
                  showCustomIcons: false,
                  showSuccessIcon: false,
                },
              ],
            },
            {
              flexSpan: 1,
              className: 'form-locations',
              marginRight: '15px',
              component: [
                {
                  name: 'street',
                  label: 'Tu dirección',
                  id: 'street',
                  type: 'autocomplete',
                  getOptionLabel: (option) => option || '',
                  variant: 'outlined',
                  noOptionsText: 'Sin opciones',
                  showCustomIcons: false,
                  showSuccessIcon: false,
                  locationAutoComplete: true,
                  clearOnBlur: false,
                },
              ],
            },
          ],
        },
        {
          type: 'formRow',
          components: [
            {
              flexSpan: 1,
              className: 'form-address',
              component: [
                {
                  name: 'tower',
                  label: 'Torre',
                  id: 'tower',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 3,
                 },
                },
              ],
            },
            {
              flexSpan: 1,
              className: 'form-address',
              component: [
                {
                  name: 'apartment',
                  label: 'Apartamento',
                  id: 'apartment',
                  type: 'text',
                  variant: 'outlined',
                  optional: true,
                  inputProps: {
                    maxLength: 5,
                    mask: formConfig.mask['CO'].apartment
                 },
                },
              ],
            },
            {
              flexSpan: 1,
              className: 'form-locations form-locations-apt',
              marginRight: '15px',
              component: [
                {
                  name: 'directionInfo',
                  label: 'Información adicional a tu dirección (omitir palabra PISO)',
                  id: 'aptNo',
                  type: 'text',
                  variant: 'outlined',
                  inputProps: {
                    maxLength: 50,
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'linkWithLabel',
          label: CO_TEXTS.ACCEPT_CONTENT_INPUT_ADDRESS,
          link: CO_TEXTS.TERMS_CONDITIONS,
          externalLink: 'https://assets.tigocloud.net/j1bxozgharz5/1QHObunYvEAeGgyaHqDEpN/66c4945c51ad3cf928977d8bbe33a6f6/Legales_Tigo.pdf',
          id: 'linkWithLabel',
          labelStyles: { color: '#1D1D1F' },
        },
        {
          type: 'buttonLoader',
          text: 'VER COBERTURA',
          name: 'submit',
          alignSelf: 'center',
          variant: 'secondary',
          style: {
            borderRadius: '18px',
            minWidth: '146px',
          },
          id: 'button',
        },
      ],

      redirectURLs: {
        nextURL: pageURL_CO.hfcFailed,
        jumpToURL: pageURL_CO.inputMap,
        noAddress: pageURL_CO.noAddress,
        packages: pageURL_CO.packages,
        noCoverage: pageURL_CO.noCoverage,
      },
      zendeskLocationsModel: {
        name: 'Cliente Sin Nombre',
        email: null,
        subject: 'Estoy interesado en un plan Hogar',
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        acceptText: 'Acepto las políticas de privacidad y tratamiento de datos personales.',
        brand_id: 4987608126739,
        ticket_form_id: 7753182686611,
        custom_fields: [
          {
            id: '1500009061601',
            value: 'b2c_tiposerv_fijo',
          },
          {
            id: '1500010742881',
            value: 'b2c_solicitudfijo_ventas',
          },
          {
            id: '4926440549907',
            value: 'Cliente Sin Nombre',
          },
          {
            id: '1500010803682',
            value: '',
          },
          {
            id: '1500010742761',
            value: '',
          },
          {
            id: '1500010742781',
            value: '',
          },
          {
            id: '1500010803662',
            value: '',
          },
          {
            id: '4926471030675',
            value: '',
          },
          {
            id: '1900002482025',
            value: 'b2c_prod_hogar_solicitudservicio',
          },
          {
            id: '1900002482065',
            value: '',
          },
          {
            id: '1500010836822',
            value: 'PENDIENTE',
          },
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_datosinsuficientes',
          },
        ],
      },
      trackEvents: {
        onMount: () => track(ADDRESS_FORM_LOADED),
        onDirectionClick: () =>
          track(EXTERNAL_LINK_CLICKED, {
            flag: 'Direccion paso a paso - map address',
            link_url: `${appConfig.apiURL}/direccion-detallada?utm_source=landing&utm_medium=ref&utm_campaign=dig-ha-suscripcion-home&utm_content=auto-g-transacciones&utm_term=click-boton-paso-a-paso--map-address`,
          }),
      },
    },
    existingClient: {
      externalPrepaid: CO_TEXTS.EXISTING_CLIENT_MITIGO,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoTitleText: '¡Notamos que ya eres cliente TIGO!',
      infoIcon: imageURLs.hfc,
      infoTitleSubText: 'Encontramos que ya tienes servicios fijos Tigo en esta ubicación. Te recomendamos mejorar tu plan actual, o si deseas, agrega una nueva dirección.',
      infoTitleSubText1: '',
      infoTitleSubText2: '',
      bannerImage: imageURLs.hfcBanner,
      mobileBanner: imageURLs.hfcBannerMobile,
      footerText: '¡Siempre 2X1 en tus paquetes. Con voz Ilimitada Nacional y datos, pasándote a TIGO!.',
      additionalText: 'PÁSATE YA',
      directionText: 'CAMBIAR DIRECCIÓN',
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_validacion-cliente-nuevo'],
      },
      zendeskUpdateCall: true,
      trackEvents: {
        onMount: () => {},
        onPostpagoClick: () => {},
        onPrepagoClick: () => {},
      },
      endFlow: false,
    },
    existingClientNewDirection: {
      externalPrepaid: CO_TEXTS.PREPAID_EXTERNAL,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoTitleText: '¡Hola :User!',
      infoIcon: imageURLs.hfc,
      infoTitleSubText: 'Necesitas un Plan TIGO para una nueva dirección. ¡Excelente!',
      infoTitleSubContent: 'Espera nuestra llamada, un asesor se comunicará contigo.',
      // bannerImage: imageURLs.hfcBanner,
      // mobileBanner: imageURLs.hfcBannerMobile,
      footerText: '¡Siempre 2X1 en tus paquetes. Con voz Ilimitada Nacional y datos, pasándote a TIGO!.',
      additionalText: 'PÁSATE YA',
      directionText: 'CAMBIAR DIRECCIÓN',
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_validacion-cliente-nuevo'],
      },
      zendeskUpdateCall: false,
      showUserName: true,
      showDirection: false,
      trackEvents: {
        onMount: () => {},
        onDirectionClick: () => {},
        onPostpagoClick: () => {},
        onPrepagoClick: () => {},
      },
      endFlow: false,
      showBack: false,
      buttonTextPostpaid: 'POSPAGO',
    },
    creditScoreRejected: {
      contentful: true,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoIcon: imageURLs.hfc,
      showBack: false,
      endFlow: false,
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_LISIM-validation'],
        custom_fields: [
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_gestion',
          },
        ],
      },
      zendeskUpdateCall: true,
      showUserName: false,
      showDirection: false,
      trackEvents: {
        onMount: () => {},
        onDirectionClick: () => {},
        onPostpagoClick: () => {},
        onPrepagoClick: () => {},
      },
    },
    creditScoreRejectedLow: {
      contentful: true,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoIcon: imageURLs.hfc,
      showBack: false,
      endFlow: false,
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_LISIM-validation'],
        custom_fields: [
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_gestion',
          },
        ],
      },
      zendeskUpdateCall: true,
      showUserName: false,
      showDirection: false,
      trackEvents: {
        onMount: () => {},
        onDirectionClick: () => {},
        onPostpagoClick: () => {},
        onPrepagoClick: () => {},
      },
    },
    antiFraudValidationFail: {
      contentful: true,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoIcon: imageURLs.hfc,
      showBack: false,
      endFlow: false,
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        additional_tags: ['ha_ROC-validacion'],
        custom_fields: [
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_gestion',
          },
        ],
      },
      zendeskUpdateCall: true,
      showUserName: false,
      showDirection: false,
      trackEvents: {
        onMount: () => {},
        onDirectionClick: () => {},
        onPostpagoClick: () => {},
        onPrepagoClick: () => {},
      },
    },
    verificationError: {
      contentful: true,
      externalPrepaid: CO_TEXTS.PREPAID_EXTERNAL,
      externalPostpaid: CO_TEXTS.POSTPAID_EXTERNAL,
      redirectURLs: { prevURL: pageURL_CO.inputAddress },
      infoIcon: imageURLs.hfc,
      zendeskHFCModel: {
        commentBody: 'Hola,estoy interesado en un plan nuevo de Hogar. \n Mis datos son los siguientes:',
        tags: ['b2c_formulario_ventas', 'homeactivator'],
        additional_tags: ['ha_evidente-validation'],
        custom_fields: [
          {
            id: '1900002482045',
            value: 'ventas_pedido_progreso_gestion',
          },
        ],
      },
      zendeskUpdateCall: true,
      showUserName: false,
      showDirection: false,
      endFlow: true,
      trackEvents: {
        onMount: () => track('Ineligibility Notified'),
        onDirectionClick: () => {},
        onPostpagoClick: (url) => track('External Link Clicked', { flag: 'Ver Pospago', link_url: url }),
        onPrepagoClick: (url) => track('External Link Clicked', { flag: 'Prepago', link_url: url }),
      },
    },
  };
  return config?.[pageName] || {};
};
