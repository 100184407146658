export const getStratum = ({ coverage = {}, new_client = true, stratus = 0, municipalityCode = '', skip = true }) => {
  const stratum = stratus ? Number(stratus) : 0;
  let tecnologia = 0;
  let technology = '';
  if(coverage.technologyList?.[0]) {
    technology = coverage.technologyList[0].name || '';
  } else {
    technology = coverage.technologyList?.name || '';
  }
  if (technology?.toLowerCase() === 'hfc' && !skip) {
    tecnologia = 1000;
  }
  if (technology?.toLowerCase() === 'gpon' && !skip) {
    tecnologia = 3000;
  }

  let ambiente = 0;
  if (['production'].includes(process.env['REACT_APP_ENVIRONMENT']?.toLowerCase()) && !skip) {
    ambiente = 100;
  }

  let oferta = 10;
  if (!new_client && !skip) {
    oferta = 20;
  }

  if(municipalityCode) {
    return `${tecnologia + ambiente + oferta + stratum}` + municipalityCode.slice(0, 5);
  }
  return `${tecnologia + ambiente + oferta + stratum}`;
};

export const logoClickAction = () => {
  window.open('https://www.tigo.com.co', '_blank');
};
