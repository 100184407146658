import fetchApi from '../../services/fetch';
import { coAPI, fixedRequestHeaders } from '../../constants/api';
import { numberFormatting, getProductDetailsCO } from '../../utils/utils';
import { track, generateServerResponseError, generateMessageDisplayedSegment } from '../../utils/segmenTracker';
import {
  ACTIVATION,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
  SERVER_RESPONSE_OK,
} from '../../constants/segmentEvents';
import { get } from 'lodash';
import { getStratum } from 'utils/utils_CO';

const initialState = {
  selectedAddons: [],
  packages: [],
  currentSelected: [],
  activationCost: {},
  monthsContract: '0 meses',
  tv: '1',
  priority: '1',
  preselected: false,
  accordian: false,
  isConvergent: false,
};

export const packages = {
  state: { ...initialState },
  reducers: {
    updatePackagesState(state, payload) {
      return { ...payload }
    },
    updateConvergentOffer(state, payload) {
      return { ...state, isConvergent: payload }
    },
    saveSelection(state, payload) {
      const currentSelection = state.currentSelected.find((selected) => selected.id === payload.id);
      let filteredSelection;
      if (currentSelection) {
        filteredSelection = state.currentSelected.filter((selection) => selection.id !== payload.id);
      } else {
        filteredSelection = [...state.currentSelected, payload];
      }

      return { ...state, currentSelected: filteredSelection };
    },
    updateAccordian(state) {
      return { ...state, accordian: !state.accordian };
    },
    setPreselected(state, payload) {
      return { ...state, currentSelected: [payload], preselected: true };
    },
    updatePackageList(state, payload) {
      return { ...state, packages: payload };
    },
    updateCurrentSelectedPackage(state, payload) {
      return { ...state, currentSelected: payload };
    },
    updateCurrentSelectedAddons(state, payload) {
      return { ...state, selectedAddons: payload };
    },
    updatePriority(state, payload) {
      return { ...state, priority: payload };
    },
    switchPriority(state, updatedPackage) {
      return { ...state, packages: updatedPackage };
    },
    removeSelected(state, payload) {
      return {
        ...state,
        selectedAddons: state.selectedAddons.filter((selected) => selected.id !== payload.id),
        currentSelected: state.currentSelected.filter((selected) => selected.id !== payload.id),
      };
    },
    clearPackages() {
      return {
        ...initialState,
      };
    },
    clearCart(state) {
      return {
        ...state,
        selectedAddons: initialState.selectedAddons,
        currentSelected: initialState.currentSelected,
        activationCost: initialState.activationCost,
        monthsContract: initialState.monthsContract,
        priority: initialState.priority,
      };
    },

    updateActivationCost(state, payload) {
      return {
        ...state,
        activationCost: payload,
      };
    },
    updateActivationMonth(state, payload) {
      return {
        ...state,
        monthsContract: payload,
      };
    },
    addToCart(state, payload) {
      return {
        ...state,
        selectedAddons: payload,
      };
    },
    tvNumber(state, payload) {
      return {
        ...state,
        tv: payload,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async getPackages({ callback, new_client = true }, state) {
        dispatch.apiStatus.setProcessing(true);
        const stratum = getStratum({
          coverage: state?.inputAddress?.coverage,
          new_client: new_client,
          stratus: state.inputAddress?.locations?.stratum,
          municipalityCode: state.inputAddress?.locations?.municipalityCode,
          skip: false,
        });
        fetchApi({
          method: 'POST',
          url: coAPI.packages,
          data: {
            ...fixedRequestHeaders.co.packages,
            'eligibility-contexts.asset.location.name': stratum,
          },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.packages,
            });
            const { productOfferingsList } = response.data;
            const packageList = productOfferingsList.map((product) => {
              const price = get(product, 'priceList[0].price.taxIncludedAmount', '');
              return {
                product: {
                  commercialEnrichmentsList: product.commercialEnrichmentsList,
                  nameProduct: product.nameProduct,
                },
                isTactical: product.tactical? true : false,
                priceValue: price,
                id: product.productOfferingUUID,
                description: getProductDetailsCO(product).description,
                name: getProductDetailsCO(product).name,
                priceList: [
                  {
                    value: numberFormatting(parseInt(price)),
                  },
                ],
                resourceList: get(
                  product,
                  'bundledItemsList[0].commercialEnrichmentsList.descriptions.entries',
                  []
                ).map((i) => ({
                  name: i.key,
                  value: i.value,
                  type: null,
                  attributes: i.attributes ? i.attributes : null,
                })),

                appCortesia: get(
                  product,
                  'bundledItemsList[0].commercialEnrichmentsList.descriptions.entries',
                  []
                ).some((i) => i.key === 'Apps Cortesía'),

                offerType: 'activation primary',
                addOns:
                  product.allowanceList && product.allowanceList.length
                    ? product.allowanceList.map((p) => {
                      const priceValue = p.pricesList[0].price.taxIncludedAmount;
                      return {
                        discountsListId: p?.discountsList?.[0]?.id,
                        priceValue,
                        nameProductId: p.nameProduct?.[0]?.id,
                        id: p.productOfferingUUID,
                        name: p.commercialEnrichmentsList?.[0]?.descriptions?.entries?.[0]?.value,
                        description: p.commercialEnrichmentsList?.[0]?.descriptions?.entries?.[0]?.value,
                        productType: p.commercialEnrichmentsList?.[0]?.descriptions?.entries?.find(
                          (pro) => pro.key === 'legal-name'
                        )?.value,
                        priceList: [
                          {
                            value: numberFormatting(parseInt(priceValue)),
                          },
                        ],
                        resourceList:
                          p.bundledItemsList?.[0]?.commercialEnrichmentsList?.[0]?.descriptions?.entries?.map(
                            (i) => ({ name: i.key, value: i.value, type: null })
                          ),
                        addonBenefits: p.commercialEnrichmentsList?.[0]?.descriptions?.entries?.map((i) => ({
                          name: i.key,
                          value: i.value,
                          type: null,
                        })),
                        offerType: 'activation supplementary',
                        imgURL: getProductDetailsCO(p).addonLogo,
                        discountName: getProductDetailsCO(get(p, 'discountsList[0]', [])).discountDescription,
                      };
                    })
                    : [],
                priority: product?.priority,
              };
            })?.sort((a, b) => {
              if(a.isTactical && !b.isTactical) {
                return -1;
              } else if(!a.isTactical && b.isTactical) {
                return 1;
              } else if(a.isTactical && b.isTactical) {
                return 0
              } else {
                return a?.priceList?.[0]?.value - b?.priceList?.[0]?.value
              }
            });

            if (
              state.packages?.currentSelected?.length &&
              packageList?.find((x) => x.id === state.packages?.currentSelected?.[0]?.id)
            ) {
              // already selected
              dispatch.packages.updatePackageList(packageList);
            } else {
              this.clearPackages();
              dispatch.packages.updatePackageList(packageList);
              dispatch.packages.saveSelection(packageList?.[0]);
            }
            if (callback) callback();
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Direccion Detallada', coAPI.packages, 'Offers API'),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(error, ACTIVATION, 'Offers API') });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async getPackagesList({ stratus, coverage, new_client = true, municipalityCode }, state) {
        dispatch.apiStatus.setProcessing(true);
        const stratum = getStratum({
          coverage: coverage,
          new_client: new_client,
          stratus: stratus,
          municipalityCode: municipalityCode,
          skip: false,
        });
        return fetchApi({
          method: 'POST',
          url: coAPI.packages,
          data: {
            ...fixedRequestHeaders.co.packages,
            'eligibility-contexts.asset.location.name': stratum,
          },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            return Promise.resolve({ success: true, data: response?.data });
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      Swicth(updatedPackage) {
        dispatch.packages.switchPriority(updatedPackage);
      },
    };
  },
};
