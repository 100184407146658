import fetchApi from 'services/fetch';
import { coAPI, fixedRequestHeaders } from 'constants/api';
import { UIConfig } from 'config/UIConfig_CO';
import { encryptData } from 'utils/secureTransfer';
import {
  track,
  updateGlobalTracker,
  generateServerResponseError,
  generateMessageDisplayedSegment,
} from 'utils/segmenTracker';
import { SERVER_RESPONSE_FAILED, MESSAGE_DISPLAYED, ACTIVATION, SERVER_RESPONSE_OK } from 'constants/segmentEvents';

const initialState = {
  data: {
    documentType: 'CC',
    clientValidity: ':cliente_validado',
  },
  dataCreditoInfo: {},
  convergente: false,
};

export const registration = {
  state: { ...initialState },
  reducers: {
    updateRegistrationState(state, payload) {
      return { ...payload }
    },
    updateRegistration(state, payload) {
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    },
    updateDataCredito(state, payload) {
      return {
        ...state,
        dataCreditoInfo: payload,
      };
    },
    clearRegistration() {
      return { ...initialState };
    },
    updateRegistrationStatus(state, payload) {
      return { ...state, isRegistered: payload };
    },
    updateRegistrationNewData(state, payload) {
      return { ...state, isNewData: payload };
    },
    updateConvergente(state, payload) {
      return { ...state, convergente: payload }
    },
  },
  effects: (dispatch) => {
    return {
      async validateUserDocument({ navigateTo, price = null, ...rest }, state) {
        const { zendeskRegisterModel } = UIConfig('PersonalInfo');
        dispatch.apiStatus.setProcessing(true);
        const data = {
          ...{
            ...fixedRequestHeaders.co.documentVerification,
            numeroId: await encryptData(fixedRequestHeaders.co.documentVerification.numeroId),
            nit: await encryptData(fixedRequestHeaders.co.documentVerification.nit),
            tipoId: await encryptData(fixedRequestHeaders.co.documentVerification.tipoId),
            validarNombre: `${fixedRequestHeaders.co.documentVerification.validarNombre}`,
          },
          ...rest,
        };
        fetchApi({
          method: 'post',
          url: coAPI.register,
          data,
        })
          .then(async (response) => {
            const {
              data: { reporte },
            } = response;
            if (reporte) {
              this.updateDataCredito(reporte);
              await dispatch.zendesk.updateRegisterZendesk({ model: zendeskRegisterModel, price: price });
              dispatch.apiStatus.setProcessing(false);
              navigateTo();
              return;
            }
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.register,
            });
            dispatch.apiStatus.setRequestFailed({ message: 'Error de servidor interno' });
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(
                error,
                ACTIVATION,
                'ID Review',
                coAPI.register,
                'Selfcare Information API'
              ),
            });
            track(MESSAGE_DISPLAYED, {
              ...generateMessageDisplayedSegment(error, ACTIVATION, 'Selfcare Information API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
    };
  },
};
