const initialState = {
  answers: [],
  response: {},
  sourceOfRedirection: false,
  attempts: 0,
  otpAttempts: 1,
};

export const varification = {
  state: { ...initialState },
  reducers: {
    updateVerificationState(state, payload) {
      return { ...payload };
    },
    updateSourceOfRedirect(state, payload) {
      return {
        ...state,
        sourceOfRedirection: payload,
      };
    },
    updateAns(state, payload) {
      return {
        ...state,
        answers: [...payload],
      };
    },
    updateResponse(state, payload) {
      return {
        ...state,
        response: payload,
      };
    },
    updateBasketRetry(state) {
      return {
        ...state,
        attempts: state.attempts + 1,
      };
    },
    updateOTPRetry(state) {
      return {
        ...state,
        otpAttempts: state.attempts + 1,
      };
    },
  },

  effects: (dispatch) => {
    return {};
  },
};
