import { globalTrackValues } from 'utils/segmenTracker';
import { getCountry, replaceSpecialChars, removeWhiteSpace } from 'utils/utils';
import { encryptData } from 'utils/secureTransfer';
import moment from 'moment';

const expirationInDays = {
    stage1: 30,
    stage2: 30,
    stage3: 30,
    stage4: 5
}

export const getCreateBasketParams = (url, id, data) => {
    const { inputAddress, packages, registration, varification, zendesk } = data;
    const country = getCountry();
    return {
        id: getFormattedNumber(id),
        stageData: {
            stage1: {
                inputAddress,
                packages,
                registration,
                varification,
                zendesk,
                trackValues: globalTrackValues[country],
                nextUrl: url,
                updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration: expirationInDays?.stage1
            },
            createdAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            lastUrl: url
        }
    };
}

export const getUpateBasketParams = (url, data, stage) => {
    const country = getCountry();
    switch (stage) {
        case "stage1":
            return getStage1(url, data, country);
        case "stage2":
            return getStage2(url, data, country);
        case "stage3":
            return getStage3(url, data, country);
        case "stage4":
            return getStage4(url, data, country);
        default:
            return {};
    }
}

export const getFormattedNumber = (val) => {
    return removeWhiteSpace(replaceSpecialChars(val));
}

export const getTransactionPayload = async (id) => {
    return Promise.resolve({ id: await encryptData(getFormattedNumber(id)), limit: '1', offset: '0' });
}

export const getBasketDetailsPayload = async ({ id, transId }) => {
    return Promise.resolve({ id: await encryptData(getFormattedNumber(id)), transactionId: await encryptData(transId) });
}

export const getEncryptedParams = async (val) => {
    return Promise.resolve({
        id: await encryptData(getFormattedNumber(val?.id)),
        stageData: await encryptData(JSON.stringify(val?.stageData)),
    });
}

export const getEncryptedUpdateParams = async (val) => {
    return Promise.resolve({
        id: await encryptData(getFormattedNumber(val?.id)),
        stageData: await encryptData(JSON.stringify(val?.stageData)),
        transactionId: await encryptData(val?.transactionId)
    });
}

export const getStage1 = (url, data, country) => {
    const { inputAddress, packages, registration, varification, zendesk, basket } = data;
    return {
        id: inputAddress?.locations?.cliTelephone,
        stageData: {
            stage1: {
                inputAddress,
                packages,
                registration,
                varification,
                zendesk,
                trackValues: globalTrackValues[country],
                nextUrl: url,
                updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration: expirationInDays?.stage1
            },
            createdAt: data?.basket?.stageData?.createdAt,
            updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            lastUrl: url
        },
        transactionId: basket?.transactionId
    }
}

export const getStage2 = (url, data, country) => {
    const { inputAddress, packages, registration, varification, zendesk, basket } = data;
    return {
        id: inputAddress?.locations?.cliTelephone,
        stageData: {
            stage1: basket?.stageData?.stage1 || {},
            stage2: {
                inputAddress,
                packages,
                registration,
                varification,
                zendesk,
                trackValues: globalTrackValues[country],
                nextUrl: url,
                updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration: expirationInDays?.stage2
            },
            createdAt: data?.basket?.stageData?.createdAt,
            updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            lastUrl: url
        },
        transactionId: basket?.transactionId
    }
}

export const getStage3 = (url, data, country) => {
    const { inputAddress, packages, registration, varification, zendesk, basket } = data;
    return {
        id: inputAddress?.locations?.cliTelephone,
        stageData: {
            stage1: basket?.stageData?.stage1 || {},
            stage2: basket?.stageData?.stage2 || {},
            stage3: {
                inputAddress,
                packages,
                registration,
                varification,
                zendesk,
                trackValues: globalTrackValues[country],
                nextUrl: url,
                updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration: expirationInDays?.stage3
            },
            createdAt: data?.basket?.stageData?.createdAt,
            updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            lastUrl: url
        },
        transactionId: basket?.transactionId
    }
}

export const getStage4 = (url, data, country) => {
    const { inputAddress, packages, registration, varification, zendesk, basket } = data;
    return {
        id: inputAddress?.locations?.cliTelephone,
        stageData: {
            stage1: basket?.stageData?.stage1 || {},
            stage2: basket?.stageData?.stage2 || {},
            ...(!packages?.isConvergent && { stage3: basket?.stageData?.stage3 }),
            stage4: {
                inputAddress,
                packages,
                registration,
                varification,
                zendesk,
                trackValues: globalTrackValues[country],
                nextUrl: url,
                updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration: expirationInDays?.stage4
            },
            createdAt: data?.basket?.stageData?.createdAt,
            updatedAt: moment().utc().format('YYYY-MM-DD hh:mm:ss'),
            lastUrl: url
        },
        transactionId: basket?.transactionId
    }
}

export const removeSecurityAns = (data) => {
    if (data?.nextUrl.includes('pregunta-seguridad') || data?.nextUrl === '/validacion-otp') {
        const { answers, response, ...rest } = data.varification;
        return { answers: [], response: { totalPrice: data?.varification?.response?.totalPrice }, ...rest };
    } else if (data?.nextUrl === '/informacion-personal') {
        return { answers: [], response: {}, sourceOfRedirection: false };
    }
    return data.varification;
}

export const getRegistrationData = (val) => {
    if (val?.nextUrl === '/informacion-personal') {
        return {
            data: { documentType: 'CC', clientValidity: ':cliente_validado', UtmCampaign: val?.registration?.data?.UtmCampaign },
            dataCreditoInfo: {}
        }
    }
    return val.registration;
}

export let isNewUser = false;

export const updateIsNewUser = (val) => {
    isNewUser = val;
}