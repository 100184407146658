import fetchApi from '../../services/fetch';
import { coAPI } from '../../constants/api';
import { track, generateServerResponseError } from 'utils/segmenTracker';
import { SERVER_RESPONSE_FAILED, ACTIVATION, SERVER_RESPONSE_OK } from 'constants/segmentEvents';

const initialState = {
  modalStatus: {},
  inactivity: {
    showed: false,
  },
  transversalHelp: {},
  currentModalStatus: {
    abandonment: false,
    inactivity: false,
    transversalHelp: false,
    helpOnOffers: false,
  },
  apiStatus: { isProcessing: false, has: false },
  customerService: {},
  contentfulData: null,
};

export const contentful = {
  state: { ...initialState },
  reducers: {
    updateStatus(state, payload) {
      return { ...state, modalStatus: { ...state.modalStatus, ...payload } };
    },
    updateInactivity(state, payload) {
      return { ...state, inactivity: { ...state.inactivity, ...payload } };
    },
    updateCustomerService(state, payload) {
      return { ...state, customerService: { ...state.customerService, ...payload } };
    },
    updateTransversalHelp(state, payload) {
      return { ...state, transversalHelp: { ...state.transversalHelp, ...payload } };
    },
    updateCurrentModalStatus(state, payload) {
      return { ...state, currentModalStatus: { ...state.currentModalStatus, ...payload } };
    },
    setProcessing(state, payload) {
      return {
        ...state,
        apiStatus: { ...state.apiStatus, isProcessing: payload },
      };
    },
    updateContentfulData(state, payload) {
      return { ...state, contentfulData: payload };
    },
  },

  effects: (dispatch) => {
    return {
      async getContentful({ payload, callBack }) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.contentful,
          data: { ...payload },
          headers: {
            exclude: true,
            excludeRes: true,
          },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.contentful,
            });
            if (callBack) callBack(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Abandoned/Incativity Modal', coAPI.orders, 'Contentful API'),
            });
          });
      },
      async submitOrder({ payload, config = {}, callBack, description, click_source = '' }) {
        this.setProcessing(true);
        return fetchApi({
          method: 'POST',
          url: coAPI.orders,
          data: { ...payload },
        })
          .then((res) => {
            this.setProcessing(false);
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...res?.data }],
              endpoint: coAPI.orders,
            });
            if (res?.data?.error?.status === 429) {
              dispatch.apiStatus.setRequestFailed({
                type: 'locationError',
                description,
              });
              track('Schedule Call Failed', { journey: ACTIVATION, click_source });
            } else {
              dispatch.apiStatus.setRequestFailed({
                type: 'success',
                description: config.toastSuccessMsg,
              });
              track('Schedule Call Succeeded', { journey: ACTIVATION, click_source });
              if (callBack) callBack();
            }
          })
          .catch((error) => {
            this.setProcessing(false);
            dispatch.apiStatus.setRequestFailed({
              type: 'locationError',
              description: config.toastErrorMsg,
            });
            track('Schedule Call Failed', { journey: ACTIVATION, click_source });
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Abandoned/Incativity Modal', coAPI.orders, 'Orders API'),
            });
          });
      },

      async updateOptin({ number, screen_name }) {
        dispatch.apiStatus.setProcessing(true);
        if (screen_name) track('Client Opt In Service Requested', { journey: ACTIVATION, screen_name });
        return fetchApi({
          method: 'POST',
          url: coAPI.brazeEvents,
          data: {
            event_header: {
              country: 'co',
              customer_action: 'notification',
              event_name: 'WHATS APP OPT IN - OPT OUT',
              event_code: 'WAOPINOUT-ALLCOUNTRIES',
              event_description: 'Segmentacion de Whats App OPT IN OPT OUT',
              event_uuid: 'uuidOne',
              prospective_lead: 'true',
              event_source: 'homeactivator',
            },
            event_data: {},
            customer_profile: {
              phone_number_main_contact: `${number}`,
              anonymousId: `${number}`,
              account_flags: {
                wa_notification: 'true',
              },
            },
          },
        })
          .then((response) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.optin,
            });
            if (screen_name) track('Client Opt In Service Succeeded', { journey: ACTIVATION, screen_name });
          })
          .catch((error) => {
            dispatch.apiStatus.setProcessing(false);
            if (screen_name) track('Client Opt In Service Failed', { journey: ACTIVATION, screen_name });
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, 'Abandoned/Incativity Modal', coAPI.optin, 'Optin API'),
            });
          });
      },
    };
  },
};
