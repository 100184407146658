import fetchApi from '../../services/fetch';
import { coAPI } from '../../constants/api';
import zendeskParams, { getCommentBody, getCustomFields } from '../../utils/zendesk';
import { updateGlobalTracker } from '../../utils/segmenTracker';
import { encryptData } from 'utils/secureTransfer';
import { accentReplace } from 'utils/textFormatter';
import { track, generateServerResponseError } from 'utils/segmenTracker';
import { SERVER_RESPONSE_FAILED, ACTIVATION, SERVER_RESPONSE_OK } from 'constants/segmentEvents';


const initialState = {
  data: {
    department: '',
    city: '',
  },
  ticketID: '',
  created: false,
  orderZendeskSent: false,
  ticketUpdate: {
    address: false,
    registration: false,
  },
  zenCustomFields: [],
  zendeskCreating: false,
};

const mapDocumentType = (doc) => {
  const mapDocs = [
    { type: 'cc', value: 'tipodoc_identidad' },
    { type: 'ce', value: 'tipodoc_extranjeria' },
    { type: 'pasaporte', value: 'tipodoc_pasaporte' },
    { type: 'nit', value: 'tipodoc_idempresarial' },
    { type: 'licencia', value: 'tipodoc_licenciaconduccion' },
  ];
  return mapDocs.find((dt) => dt.type === doc.toLowerCase())?.value;
};

export const zendesk = {
  state: { ...initialState },
  reducers: {
    updateZendeskState(state, payload) {
      return { ...payload };
    },
    updateValuesZendesk: (state, payload) => {
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    },
    setTicketID: (state, payload) => {
      return {
        ...state,
        ticketID: payload,
        created: true,
      };
    },
    updateTicketStatus: (state, payload) => {
      return {
        ...state,
        ticketUpdate: { ...state.ticketUpdate, ...payload },
      };
    },
    updateZendeskCustomFields: (state, payload) => {
      return {
        ...state,
        zenCustomFields: payload,
      };
    },
    orderZendeskSentSucess: (state, payload) => {
      return {
        ...state,
        orderZendeskSent: true,
      };
    },
    updateZendeskCreating: (state, payload) => {
      return {
        ...state,
        zendeskCreating: payload,
      };
    },
    clearZendesk: () => {
      return { ...initialState };
    },
  },
  effects: (dispatch) => {
    return {
      async createZendesk(model, state) {
        dispatch.zendesk.updateZendeskCreating(true);
        const params = await zendeskParams(state, model);
        dispatch.zendesk.updateZendeskCustomFields(params?.ticket?.custom_fields);
        if (['development', 'staging'].includes(process.env['REACT_APP_ENVIRONMENT']?.toLowerCase())) {
          params.ticket.tags.push('qa_home_activator');
        }
        return fetchApi({
          method: 'post',
          url: coAPI.zendesk,
          data: params,
        })
          .then((response) => {
            const {
              data: { ticket },
            } = response;
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.zendesk,
            });
            updateGlobalTracker({ lead_id: ticket.id });
            dispatch.zendesk.setTicketID(ticket.id);
            dispatch.zendesk.updateZendeskCreating(false);
            return ticket;
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, '', coAPI.zendesk, 'Zendesk API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
            dispatch.zendesk.updateZendeskCreating(false);
            return null;
          });
      },

      //Agregar aquí la asignación del updateRegisterZendesk
      async updatePackageZendesk({ model, price }, state) {
        dispatch.apiStatus.setProcessing(true);
        const { description, priceList } = state.packages.currentSelected[0];
        const { value } = priceList[0];
        const { ticketID, zenCustomFields } = state.zendesk;
        const { custom_fields } = model;
        
        let customFields = custom_fields;
        customFields[3].value = `${description} ${value}`;
        const modifiedField = await getCustomFields(zenCustomFields, custom_fields);
        const { cliFirstName, cliLastName, cliEmail } = state.registration?.data;
        dispatch.zendesk.updateZendeskCustomFields(modifiedField);

        const name = `${accentReplace(cliFirstName || '')} ${accentReplace(cliLastName || '')}`;
        const phone = state.inputAddress?.locations?.cliTelephone?.replace(/[^\d]/g, '');
        let req = {};
        if (cliEmail) {
          req = { email: `${cliEmail}`, name: name || ' ' };
        } else {
          req = { phone: phone, name: phone };
        }

        return fetchApi({
          method: 'put',
          url: coAPI.zendeskUpdate,
          params: {},
          data: {
            ticket: {
              requester: {
                ...req,
              },
              comment: await getCommentBody(state, model, price),
              additional_tags: ['ha_seleccion-oferta'],
              custom_fields: modifiedField,
              subject: 'Estoy interesado en un plan Hogar',
            },
            ids: `${await encryptData(ticketID)}`,
          },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.zendeskUpdate,
            });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, '', coAPI.zendeskUpdate, 'Zendesk API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },

      async updateRegisterZendesk({ model, price }, state) {
        dispatch.apiStatus.setProcessing(true);
        const { description, priceList } = state.packages.currentSelected[0];
        const { cliFirstName, cliLastName, cliEmail, documentType, documentNumber, cliTelephone } = state.registration.data;
        const { value } = priceList[0];
        const { ticketID, zenCustomFields } = state.zendesk;
        const { custom_fields } = model;

        let customFields = custom_fields;
        customFields[0].value = `${accentReplace(cliFirstName)} ${accentReplace(cliLastName)}`;
        customFields[1].value = mapDocumentType(documentType);
        customFields[2].value = `${documentNumber}`.replace(/[^\d]/g, '');
        customFields[3].value = `${description} ${value}`;
        const modifiedField = await getCustomFields(zenCustomFields, custom_fields);
        dispatch.zendesk.updateZendeskCustomFields(modifiedField);

        const name = `${accentReplace(cliFirstName || '')} ${accentReplace(cliLastName || '')}`;
        const phone = state.inputAddress?.locations?.cliTelephone?.replace(/[^\d]/g, '');
        let req = {};
        if (cliEmail) {
          req = { email: `${cliEmail}`, name: name || ' ' };
        } else {
          req = { phone: phone, name: phone };
        }

        return fetchApi({
          method: 'put',
          url: coAPI.zendeskUpdate,
          params: {},
          data: {
            ticket: {
              requester: {
                ...req,
              },
              comment: await getCommentBody(state, model, price),
              additional_tags: ['ha_informacion-personal'],
              custom_fields: modifiedField,
              subject: 'Estoy interesado en un plan Hogar',
            },
            ids: `${await encryptData(ticketID)}`,
          },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.zendeskUpdate,
            });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, '', coAPI.zendeskUpdate, 'Zendesk API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async updateOrderZendesk({ model, price }, state) {
        dispatch.apiStatus.setProcessing(true);
        const { ticketID, zenCustomFields } = state.zendesk;
        const { custom_fields } = model;
        const modifiedField = await getCustomFields(zenCustomFields, custom_fields);
        dispatch.zendesk.updateZendeskCustomFields(modifiedField);
        return fetchApi({
          method: 'put',
          url: coAPI.zendeskUpdate,
          params: {},
          data: {
            ticket: {
              comment: await getCommentBody(state, model, price),
              additional_tags: ['b2c_formulario_ventas', 'homeactivator', 'ha_solicitud-recibida'],
              custom_fields: modifiedField,
              subject: 'Estoy interesado en un plan Hogar',
            },
            ids: `${await encryptData(ticketID)}`,
          },
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.zendeskUpdate,
            });
            dispatch.zendesk.orderZendeskSentSucess();
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, '', coAPI.zendeskUpdate, 'Zendesk API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async updateZendesk(model, state) {
        dispatch.apiStatus.setProcessing(true);
        const { ticketID } = state.zendesk;
        const { tags } = model;
        const zendeskData = {
          ticket: {
            comment: await getCommentBody(state, model),
            tags: tags,
            subject: 'Estoy interesado en un plan Hogar',
          },
          ids: `${await encryptData(ticketID)}`,
        };
        if (model.custom_fields) zendeskData.ticket['custom_fields'] = model.custom_fields;
        if (model['additional_tags']) zendeskData.ticket['additional_tags'] = model['additional_tags'];
        return fetchApi({
          method: 'put',
          url: coAPI.zendeskUpdate,
          params: {},
          data: zendeskData,
        })
          .then((response) => {
            track(SERVER_RESPONSE_OK, {
              journey: ACTIVATION,
              raw_response: [{ ...response?.data }],
              endpoint: coAPI.zendeskUpdate,
            });
            dispatch.zendesk.orderZendeskSentSucess();
            dispatch.apiStatus.setProcessing(false);
            return true;
          })
          .catch((error) => {
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(error, ACTIVATION, '', coAPI.zendeskUpdate, 'Zendesk API'),
            });
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
    };
  },
};
