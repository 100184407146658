import { packages } from './packages';
import { inputAddress } from './inputAddress';
import { registration } from './registration';
import { apiStatus } from './apiStatus';
import { auth } from './auth';
import { zendesk } from './zendesk';
import { varification } from './varification';
import { contentful } from './contentful';
import { basket } from './basket';

export default {
  apiStatus,
  packages,
  registration,
  inputAddress,
  auth,
  zendesk,
  varification,
  contentful,
  basket
};
