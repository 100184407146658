export const texts = {
  CLOSE: 'cerrar',
  ENTER_ADDRESS: 'Encuentra una Dirección',
  START: 'iniciar',
  CONTINUE: 'CONTINUAR',
  packageScreens: {
    PACKAGELIST_TITLE: 'Servicios individuales',
    SERVICE_DETAILS: 'Detalles del servicio',
    DETAILS_SCREEN_DESCRIPTION: 'Aquí se pueden incluir detalles adicionales o condiciones que apliquen al plan.',
    ADD_CART: 'AÑADIR Y VER CARRITO',
    ADD_SHOP: 'AÑADIR Y SEGUIR COMPRANDO',
    PACKAGE_DETAILS: 'Detalles del paquete',
  },
  MONTHLY_PAYMENT: 'Pago mensual',
  SUBTOTAL: 'Subtotal',
  TAXES: 'Impuestos',
  MOBILEPHONE_CAHRGES: 'Cargos de telefonía móvil',
  SHOPPING_CART: 'Carrito de compras',
  KEEP_BUYING: 'SEGUIR COMPRANDO',
  REMOVE: 'ELIMINAR',
  CANCEL: 'CANCELAR',
  REMOVE_CONFIRMATION: '¿Estás seguro de querer eliminar este servicio de tu carrito de compras?',
  REMOVE_PACKAGE_POPUP_HEADING: 'Eliminar',
  CART_EMPTY_TITLE: 'Carrito de compras',
  CART_DETAILS_TABLE_TITLE: 'Resumen de cuotas - Instalación',
  NO_CART_FOUND: 'No has añadido ningún producto a tu carrito de compras',
  NEW_SEARCH: 'Nueva búsqueda',
  SEARCH_HISTORY: 'Historial de búsqueda',
  SALES_REPORT: 'Reporte de ventas',
  LOGOUT: 'Cerrar sesión',
  CONTRACT_FORM_TITLE: 'Completa estos pasos para crear la Orden de Trabajo (ODT)',
  SALES_DETAIL: 'Detalles del lead',
  CLIENT_SEARCH: 'Búsqueda de clientes',
  SEARCH: 'BUSCAR',
  CLIENT_SEARCH_RESULT: 'Resultados de tu búsqueda',
  CLIENT_SUBSCRIPTION_TITLE: 'Productos ya contratados',
  ADD_SERVICES: 'AÑADIR SERVICIOS',
  CLIENT_SEARCH_FAILED: 'No hay resultados que coincidan con tu búsqueda. Inténtalo de nuevo.',
  CONFIRM_ORDER: 'Confirma tu orden',
  CONFIRM: 'Confirmar',
  ACTIVATION_CONFIRMATION:
    'Esta acción creara la cuenta del cliente. Al dar clic en confirmar; no sera posible realizar cambios.',
  ACTIVATION_CONFIRMATION_ASSISTED: 'Esta acción enviará tu solicitud. Al dar clic en confirmar, no podrás realizar cambios',
  SUCCESS_SCREEN_BUTTON: 'Realizar una nueva Transacción',
  SUCCESS_SCREEN_HEADER: '¡Proceso realizado con éxito!',
  SUCCESS_SCREEN_HEADER_CO: '¡Tu solicitud está en proceso!',
  ENTER_PIN: 'Ingrese su PIN',
  USER_DUI_ENTER: 'Puedes usar el DUI o número de contrato.',
  USER_CONTRACT_CARD_TITLE: 'Contrato unico de servicios movles pospago N 233556565612345',
  USER_CONTRACT_CARD_SUB_TITLE: 'Linea o numero de usuario',
  USER_CONTRACT_ACCEPT_CONTRACT_LABEL:
    'Acepto que mi contrato se renueve sucesiva y automaticamente por un plazo igual al inicial',
  USER_CONTRACT_ACCEPT_CONTRACT_TITLE:
    'Este contrato explica las condiciones para la prestacion de los servicios entre usted y EL SALVADOR MOVIL S>A ESP en adelante CM, por ql que pagara minimo mensualmente $64,000 ESte contrato tendra vigencia de 12 meses, contados a partir del momento de su activacion',
  EDIT_ORDER: 'EDITAR ORDEN',
  EDIT_INSTALLATION_DATE: 'EDITAR FECHA Y HORA',
  HOMEPAGE_TITLE: 'Bienvenidos al Activator Home!',
  ENTEROKAY: 'Ingresar',
  HOME_DESCRIPTION: 'Aquí encontraras todas las ofertas disponible para nuestros clientes.',
  NO_ACTIVATION_COST_INFO:
    'No hay información de cargo de instalación con la cantidad de meses seleccionada, elige otra opción',
  INSTALLATION_COST: 'Costo de instalación',
  INSTALLATION_PAYMENT_INFO: 'Este monto debe de ser pagado al momento de instalación',
  START_SESSION: 'Inicia tu sesión',
  MODIFY: 'MODIFICAR',
  PRIMARY_PACKAGE_TITLE: 'Escoge un paquete',
  SV_PRIMARY_IDENTITY_TYPE: 'DUI',
  INVALID_EMAIL: 'la identificación de correo electrónico ya existe',
  INVALID_PHONE: 'el número de teléfono ya existe',
  REGISTRATION_ERROR: 'Algo salió mal, por favor intente después de algún tiempo.',
  INVALID_DATE: 'Fecha inválido',
  SIGNATURE_HEADING: 'ÁREA SEGURA PARA LA FIRMA',
  SIGNATURE_LEFT_TITLE: 'Tu dedo para escribir',
  SIGNATURE_RIGHT_TITLE: 'Utiliza tu lapicero stylus',
  NEW_CLIENT: 'Nuevo Cliente',
  LOCATION_ERROR: 'Para poder agreagar un nuevo cliente debes activar el servicio de localización',
  LOCATION_ERROR_NI: 'Para poder agregarte como nuevo cliente, debés activar el servicio de localización.',
  AT_CLIENTS_LOCATION: 'Me encuentro en la casa del cliente',
  INVALID_EMAIL_PHONE_CODE: 'Tu código es incorrecto, inténtalo de nuevo',
  EXPIRED_EMAIL_PHONE_CODE: 'Tu código ha expirado, solicita uno nuevo',
  REGISTRATION_VERIFICATION:
    'Para evitar demoras por favor verifica que todos tus datos coincidan con los de tu cedula.',
  CONTACT_SOON:
    'Tu solicitud será procesada en los próximos minutos y te enviaremos un correo electrónico con la confirmación del proceso.',
  GO_TO_TIGO: 'Ir a Tigo',
  PASA_NUMERO: 'Pasa mi número a tigo',
  COMPRA_LINEA: 'Compra una línea nueva',
  ERROR_EMPTY_FIELD: 'Por favor, valida la información',
  INVALID_DOCUMENT: 'El DUI ingresado no es válido',
  ACTIVATION_ERROR: 'Error en el proceso',
  ERROR_SCREEN_BUTTON: 'REALIZAR UNA NUEVA VENTA',
  UPLOAD_FROM_MOBILE: 'Quiero cargar foto de mi teléfono',
  OCR_ERROR_TEXT: 'No pudimos extraer tu información de la cédula. Por favor ingrésala manualmente.',
  SKIP: 'Omitir',
  MICUENTA_URL: 'http://micuenta.tigo.com.hn/',
  RETURN: 'regresar',
  dateAvailablityError:
    'No hay disponibilidad en las fecha que estas solicitando, por favor validar fechas posteriores',
  CONFIRM_MODIFY: 'Modificarás la información del cliente.',
  CONFIRM_MODIFY_CONTINUE: '¿querés continuar?',
  FILL_REMAINING: 'La información que modificaste requiere cambios adicionales.',
  EDIT_DETAILS: 'Editar detalles',
  EDIT_DIRECTION: 'Editar dirección',
  EDIT_PRODUCTS: 'Editar productos',
  EDIT_INFORMATION: 'Editar información',
  EDIT_REFERENCES: 'Editar referencias',
  EDIT_INSTALLATION: 'Editar instalación',
  EDIT_SIGNATURE: 'Editar Acuerdo',
  EDIT_DOCUMENT: 'Editar Documentos',
  DUI_INCORRECT:"DUI incorrecto! Favor ingresa un DUI valido."
};

export const SV_TEXTS = {
  CONFIRM_ORDER: 'Confirmar orden',
  CLIENTINFO_ERROR: 'Deuda pendiente de pago',
  PAY_NOTE: 'Se ha enviado un SMS/email con la información necesaria para realizar el pago.',
  EXTERNAL_PAY_LINK: 'Como pago mis facturas Tigo?',
};

export const HN_TEXTS = {
    HOME_TITLE: '¡Bienvenido al Activador Home!',
    HOME_DESCRIPTION: 'Aquí encontrarás toda las ofertas disponibles para los servicios en tu hogar.',
    HOME_NEW_CLIENT: 'Nuevo Cliente',
    HOME_SALES_REPORT: 'Reporte de ventas',
    HOME_LOGOUT: 'Cerrar sesión',
    HOME_BUTTON: 'Iniciar',
    CLIENT_INFORMATION_TITLE: 'Ingresa tus datos',
    ACTIVE_TRANSACTION_TITLE: 'Cuenta activa',
    ACTIVE_TRANSACTION_CONTENT: 'Tienes una transacción en proceso. Para continuar, haz clic en el botón a continuación:',
    ACTIVE_TRANSACTION_REDIRECT: 'IR A MI TIGO',
    FINISH_TRANSACTIONS: 'También puedes terminar la transacción en un',
    LOCAL_STORE_POINT: 'PUNTO DE PAGO LOCAL',
    EXISTING_SERVICES_TITLE: 'Resultados de tu búsqueda',
    CONTINUE_HOME: 'Volver al inicio',
    ACTIVATE_LOCATION_TITLE: 'Verifica la cobertura Tigo Hogar en tu zona',
    ACTIVATE_LOCATION_CONTENT: 'Revisaremos si hay cobertura en tu ubicación actual',
    ACTIVATE_LOCATION_SUB_CONTENT: 'Si recibes una solicitud de ubicación, por favor elige la opción “Permitir”.',
    INPUT_ADDRESS_TITLE: 'Ingresa tu dirección',
    MAP_COVERAGE_DESCRIPTION: 'Verifica si la ubicación es la correcta. Puedes ajustarla tocando el mapa.',
    BUTTON_NO_COVERAGE_TEXT: 'Ver Servicios móviles',
    BUTTON_COVERAGE_TEXT: 'SOLICITA el servicio ahora',
    POPUP_COVERAGE_TEXT: 'Tigo Hogar está disponible aquí.',
    POPUP_NO_COVERAGE_TEXT: 'Tigo Hogar no está disponible aquí.',
    NO_OFFER_TITLE: 'No hay oferta disponible',
    NO_OFFER_DESCRIPTION: 'En estos momentos no podemos proceder con la venta',
    PRODUCTS_SELECTION_TITLE: 'Elige un paquete',
    HN_ADDONS_TITLE: 'Resumen',
    HN_ID_FRONT_TITLE: 'Captura la Tarjeta de Identidad',
    HN_ID_FRONT_DESCRIPTION: 'A continuación tomaremos una foto de la cara frontal de la Tarjeta de Identidad.',
    HN_ID_FRONT_CAPTURE: 'TOMAR FOTO FRONTAL',
    HN_ID_REVIEW_CONFIRMATION: 'Confirma la información',
    HN_ID_UPLOAD_TEXT: 'CARGAR FOTO DE ID',
    HN_ID_INVALID_TITLE: 'Su identificación no pudo ser validada',
    HN_ID_INVALID_DESCRIPTION: 'Un representante lo va a contactar en las próximas horas',
    HN_CONTRACT_INFO_TITLE: 'Información de contacto',
    HN_CONTRACT_INFO_DESCRIPTION: 'Ingresa tu información de contacto en la siguiente sección y verifica que esté correcta.',
    HN_VERIFY_PHONE_TITLE: 'Te hemos enviado un código de verificación',
    HN_OTP_VALIDATION_SUCCESS: 'OTP Correcto',
    HN_CONFIRM_ORDER: 'Confirma tu orden',
    HN_ACTIVATION_SELECTED_SERVICES: 'Servicios contratados:',
    HN_ACTIVATION_INSTALLATION_COST: 'Pago realizado por instalación:',
    HN_ESTIMATE: 'Estimado',
    HN_ACTIVATION_PAY_AT_INSTALLATION: 'Pagar al instalador',
    HN_ORDER_SUCCESS: '¡Proceso realizado con éxito!',
    HN_ORDER_SUCCESS_BUTTON: 'nueva transacción',
    HN_SCHEDULE_VISIT_TITLE: 'Selecciona la fecha y hora para tu instalación',
    HN_CHECKOUT_TOTAL_TEXT: 'Subtotal (sin impuestos)',
    HN_CONFIRM_ACTIVATION: 'Esta acción va a crear la cuenta del cliente, después de continuar no va a ser posible realizar cambios.',
    HN_CANCEL_ORDER: 'Esta acción borrará tu progreso y no se podrá deshacer.',
    HN_CANCEL_ORDER2 :'Esta acción borrará tu proceso y no se podrá deshacer',
    HN_CANCEL_ORDER_TITLE: '¿Estás seguro de que deseas cancelar tu pedido?',
    HN_ID_MISMATCH_INFO: 'El número de identificación no coincide con el provisto al inicio de la suscripción',
    HN_PREPAID_POPUP_TITLE: 'Este plan ofrece los siguientes beneficios para tu cuenta móvil:',
    HN_PREPAID_BONUS_ENTER_MOBILE: 'Ingresa el numero Tigo donde quieres recibirlo',
    HN_PREPAID_BONUS_FOOTER: 'Este numero no podrá ser modificado después de este paso',
    HN_PREPAID_POPUP_TITLE_CONFIRM: '¿Estás seguro  que no vas a agregar un numero Tigo?',
    HN_PREPAID_BONUS_ERROR: 'El numero suministrado no puede ser usado para bonificación',
    HN_ORDER_CANCEL_REASON : 'Por favor selecciona la razón de la cancelación',
    CLIENTINFO_ERROR: 'Deuda pendiente de pago',
    PAY_NOTE: 'Se ha enviado un SMS/email con la información necesaría para realizar el pago.',
    EXTERNAL_PAY_LINK: 'Como pago mis facturas Tigo?',
    EXTERNAL_PAY_LINK_URL: 'https://www.tigo.com.hn/pago-de-facturas',
    CURRENCY_SYMBOL: 'L'
}

export const CO_TEXTS = {
    MOBILE_EXTERNAL: '//compras.tigo.com.co/movil/portabilidad?utm_source=landing&utm_medium=ref&utm_campaign=adp-et-portabilidad-mobile&utm_content=adp-diciembre&utm_term=click-boton-pasate-a-tigo',
    NEW_LINE_EXTERNAL: '//compras.tigo.com.co/movil/pospago?utm_source=landing&utm_medium=ref&utm_campaign=adp-et-cliente-nuevo-pospago-mobile&utm_content=adp-diciembre&utm_term=click-boton-compra-nueva-linea',
    LETS_TALK: '¡Sí, hablemos!',
    GOOD_CONNECTION: 'Hoy más que nunca necesitas una buena conexión, ',
    DO_NOT_MISS_OUR_OFFERS: 'no te pierdas nuestras ofertas.',
    ACCEPT_CONTENT: 'Al presionar TE LLAMAMOS Aceptas los ',
    TERMS_CONDITIONS: 'Términos y condiciones',
    CONTINUE_PURCHASE: 'Continua tu proceso de compra de Plan hogar en línea. ¡No esperes más!',
    GET_PLAN_NOW: '¡QUIERO MI PLAN AHORA!',
    TERMS_CONDITIONS_LINK: '//www.tigo.com.co/tyccontacto',
    LEAD_CONFIRMATION_TITLE: '¡Hemos recibido tu solicitud!',
    LEAD_CONFIRMATION_CONTENT: 'En breve uno de nuestros asesores se contactará contigo para darte más información',
    LEAD_CONFIRMATION_BANNER_TITLE: '¿Quieres datos y minutos ilimitados?',
    LEAD_CONFIRMATION_BANNER_TEXT: 'Agregar un plan pospago y mantenerme siempre conectado.',
    PREPAID_EXTERNAL: 'https://compras.tigo.com.co/movil/prepago?utm_source=landing&utm_medium=ref&utm_campaign=dig-et-prepago-mobile&utm_content=adp-adopcion&utm_term=sesions-boton-prepago',
    POSTPAID_EXTERNAL: 'https://compras.tigo.com.co/movil/pospago?utm_source=landing&utm_medium=ref&utm_campaign=dig-et-pospago-mobile&utm_content=adp-adopcion&utm_term=sesions-boton-ver-pospago',
    footerText: currentYear => `COPYRIGHT © ${currentYear} TIGO | Todos los derechos reservados `,
    FOOTER_LINK : `Legal y Regulatorio.`,
    fillInFields: 'Completa los campos',
    tigoHomeServices: 'Servicios hogar TIGO',
    ACCEPT_CONTENT_INPUT_ADDRESS: 'Al presionar VER COBERTURA Aceptas los ',
    BTN_ACEPTAR: 'ACEPTAR',
    loaderBtnTexts:{
        APIprocessing: 'VERIFICANDO COBERTURA',
        addressSuccess: '¡ENCONTRAMOS TU DIRECCIÓN!',
        coverageFail: 'NO ENCONTRAMOS TU DIRECCIÓN',
        coverageRetry: 'INTENTALO NUEVAMENTE'
    },
    PERSONAL_INFO_TERMS: 'Al presionar GENERAR SOLICITUD estas autorizando la consulta en centrales de riesgo y la validación de identidad con métodos propios de Tigo y/o a través de terceros.',
    PACK_RECOMENDATION_TITLE :'¡TU PLAN DE INTERNET RECOMENDADO!',
    BASIC_PLAN_VALUE : 'Valor de plan básico',
    ADD_TO_YOUR_PLAN : 'ADICIONA A TU PLAN',
    SUMMARY_OF_YOUR_PURCHASE : 'RESUMEN DE TU COMPRA',
    PORTABILITY_EXTERNAL: 'https://compras.tigo.com.co/movil/portabilidad?utm_source=landing&utm_medium=ref&utm_campaign=dig-et-pospago-mobile&utm_content=adp-adopcion&utm_term=sesions-boton-pasar-mi-numero-a-tigo',
    NEWLINE: 'https://compras.tigo.com.co/movil/pospago?utm_source=landing&utm_medium=ref&utm_campaign=dig-et-pospago-mobile&utm_content=adp-adopcion&utm_term=sesions-boton-comprar-una-linea-nueva',
    EXISTING_CLIENT_MITIGO: 'https://micuenta.tigo.com.co?utm_source=landing&utm_medium=ref&utm_campaign=dig-mtw-upgrade-home&utm_content=txs-transacciones&utm_term=click-boton-mejorar-mi-plan-actual',
    footerTextMobile: currentYear => `COPYRIGHT © ${currentYear} TIGO |  `,
    FOOTER_LINK_MOBILE: 'Todos los derechos reservados',
    OTP_VARIFICATION_HEAD: 'Verificación de seguridad',
    VER_POLITICA: "Ver detalle autorización.",
    VER_POLITICA_LINK: 'https://www.tigo.com.co/legal-y-regulatorio/politica-de-datos-personales#politicas-de-datos-personales-y-de-seguridad-aviso-de-privacidad',
    footerDetails: {
      text: '© Todos los derechos reservados.',
      linkText: 'Términos y condiciones',
      link: 'https://www.tigo.com.co/legal-y-regulatorio/politicas-tigo#politicas-tigo-terminos-y-condiciones-de-la-pagina-web'
    },
    PLAN_DESCRIPTION: 'Descripción de tu plan',
    EMPTY: '',
    BASKET_FAILS: 'Hubo un error al cargar la página. Por favor, espera unos minutos y actualiza la web.'
}

export const GT_TEXTS = {
    ...HN_TEXTS,
    HOME_TITLE: '¡Bienvenido a la herramienta de ventas Residencial!',
    HOME_DESCRIPTION: 'Aquí encontrarás toda las ofertas disponible para tu servicio en tu hogar.',
    ACTIVATE_LOCATION_TITLE: 'Verifica la cobertura Tigo Residencial en tu zona',
    INPUT_ADDRESS_TITLE: 'Ingresar dirección',
    BILL_UPLOAD_TITLE: 'Carga o toma fotos de la factura de energía eléctrica',
    BILL_CONFIRM_TITLE: 'Confirma la información',
    DOCUMENT_UPLOAD_TITLE: 'Carga o toma fotos de los siguientes documentos',
    REAR_ID_SCANNING: 'TOMAR la foto posterior',
    OTHER_DOCUMENT: 'QUIERO USAR OTRO DOCUMENTO',
    ID_FRONT_TITLE: 'Captura el documento de identidad',
    OCR_ERROR_TEXT: 'No pudimos extraer tu información del DPI. Por favor ingrésala manualmente.',
    DOC_ERROR_TEXT : 'El número de identificación no coincide con el provisto al inicio de la suscripción ',
    PRODUCTS_SELECTION_TITLE: 'Elige un plan',
    SIGNATURE_HEADING: 'ÁREA SEGURA PARA LA FIRMA',
    SIGNATURE_LEFT_TITLE: 'Tu dedo para escribir',
    SIGNATURE_RIGHT_TITLE: 'Utiliza tu lapicero stylus',
    PAYMENT_RECEIPT_TITLE: 'Agregar comprobante de pago',
    SCHEDULE_VISIT_TITLE: 'Agendar instalación',
    CONFIRM_ORDER: 'Confirmar orden',
    CONFIRM_ORDER_POPUP_TITLE: 'Esta acción va a crear la cuenta del cliente, sin posibilidad de modificación.',
    CONFIRM_ORDER_POPUP_TEXT: '¿Estás seguro que deseas continuar?',
    CANCEL_ORDER_POPUP_TITLE: '¿Estás seguro de que deseas cancelar tu pedido?',
    CANCEL_ORDER_POPUP_TEXT: 'Esta acción borrará tu progreso y no se podrá deshacer.',
    ORDER_SUCCESS_TITLE: 'Venta realizada con éxito',
    ORDER_FAILED_TITLE: 'Lo sentimos, no se pudo realizar tu activación.',
    ORDER_FAILED_BUTTON: 'Inténtalo de nuevo',
    MODIFY_EXISTING_SERVICE_TITLE: 'Servicios contratados',
    UPDATE_ADDONS_TITLE: 'Añadir más servicios',
    MAINTENANCE_TITLE: 'Home Activator se encuentra en mantenimiento para brindarte una mejor experiencia',
    MAINTENANCE_DESCRIPTION: 'Por favor, vuelve a intentarlo más tarde.',
    MAINTENANCE_BUTTON: 'actualizar',
}

export const CR_TEXTS = {
    footerText: currentYear => `COPYRIGHT © ${currentYear} TIGO | Todos los derechos reservados `,
    FOOTER_LINK : `Legal y Regulatorio.`,
    SIGNATURE_RIGHT_TITLE: 'Utiliza tu lapicero',
    CR_VERIFY_OTP_TITLE: 'Te hemos enviado un código de verificación al',
    LEAD_CALL_LEFTTEXT: 'Hoy más que nunca necesitas una buena conexión.',
    DO_NOT_MISS_OUR_OFFERS: 'No te pierdas nuestras ofertas.',
    CONTINUE_PURCHASE: 'Continúa tu proceso de compra del Plan Hogar en línea. ¡No esperes más!',
    ACCEPT_CONTENT: 'Este portal usa cookies y puede recolectar datos personales.',
    PRIVACY_CONTENT: 'Para continuar acepte nuestro ',
    PRIVACY_LINK_TEXT: 'Aviso de Privacidad',
    TERMS_CONDITIONS: 'Términos y Condiciones',
    PRIVACY_LINK: 'https://www.tigo.cr/legales#tigo-aviso-de-privacidad',
    TERMS_CONDITIONS_LINK: 'https://www.tigo.cr/legales#tigo-terminos-y-condiciones'
}

export const NI_TEXTS = {
    ...HN_TEXTS,
    HOME_TITLE: '¡Bienvenido a Home Activator!',
    HOME_DESCRIPTION: 'Gestioná tus ventas de servicios Hogar de manera rápida y eficiente.',
    BOTTOM_TEXT: 'centro de ayuda',
    MAINTENANCE_TITLE: 'Home Activator se encuentra en mantenimiento para brindarte una mejor experiencia',
    MAINTENANCE_DESCRIPTION: 'Por favor, volvé a intentarlo más tarde.',
    MAINTENANCE_BUTTON: 'actualizar',
    HOME_CATALOGUE: 'CATALOGO DE OFERTA',
    HOME_COVERAGE_MAP: 'MAPA DE COBERTURA',
    HOME_REPORT_COLLECTION: 'REPORTE DE COBRANZAS',
    HOME_REPORT_COMMISSION: 'REPORTE DE COMISIONES',
    DUE_AMOUNT: 'Deuda total: ',
    DUE_DATE: 'Fecha límite de pago: ',
    INVOICE_COUNT: 'Número de facturas vencidas: ',
    BILLING_ACCOUNT_ID: 'Número a pagar: ',
    NOTE: 'Por favor, realizá tu pago antes de continuar.',
    NI_MAP_FOOTER : 'Verificá si la ubicación es la correcta. Podés ajustarla tocando el mapa.',
    CONFIRM_TEXT: 'Esta acción va a crear la cuenta del cliente, sin posibilidad de modificación.',
    CANCEL_TEXT: 'Esta acción borrará tu progreso y no se podrá deshacer.',
    CANCEL_HEADING: '¿Estás seguro que querés cancelar tu pedido?',
    NI_VERIFY_PHONE_TITLE: 'Te hemos enviado un código de verificación al',
    NI_VERIFY_EMAIL_TITLE: 'Te hemos enviado un código de verificación a',
    ID_FRONT_TITLE: 'Capturá el documento de identidad',
    ID_REAR_TITLE: 'Foto posterior',
    ID_REAR_DESCRIPTION: 'A continuación tomaremos una foto de ambas caras de la Cédula de Identidad.',
    FRONT_ID_UPLOAD_TEXT: 'CARGAR FOTO DEL CI',
    REAR_ID_UPLOAD_TEXT: 'TOMAR LA FOTO POSTERIOR',
    ID_FRONT_CAPTURE: 'TOMAR FOTO FRONTAL',
    NI_MAP_FOOTER : 'Verificá si la ubicación es la correcta. Podés ajustarla tocando el mapa.',
    DOCUMENT_UPLOAD_TITLE: 'Cargá o tomá fotos de los siguientes documentos'
}

export const BO_TEXTS = {
    footerText: currentYear => `COPYRIGHT © ${currentYear} TIGO | Todos los derechos reservados `,
    FOOTER_LINK : `Legal y Regulatorio.`,
    VERIFY_OTP_TITLE: 'Enviamos un código de verificación a '
}

export const COOKIE_NAME = 'TIGO_ACCOUNTS';

export const stepperTexts = {
  yourAddress: 'TU DIRECCIÓN',
  yourPlan: 'TU PLAN',
  yourData: 'TUS DATOS',
  yourOrder: 'AGENDAMIENTO',
};

export const PY_TEXTS = {
  ...HN_TEXTS,
  PY_ORDER_SUCCESS_BTN: 'NUEVA TRANSACCIÓN',
  PY_ORDER_SUCCESS: 'La OT ha sido agendada con éxito',
  PY_ORDER_SUCCESS_CONTENT: 'Detalles de la transacción',
  HOME_TITLE: '¡Bienvenido al Activador de Servicios Hogar!',
  HOME_DESCRIPTION: 'Aquí encontrás toda la oferta comercial disponible para nuestros clientes',
  PRODUCT_SELECTION_TITLE: 'Elegí un paquete',
  ACTIVATE_LOCATION_TITLE: 'Verificación de cobertura HOME en tu zona',
  ACTIVATE_LOCATION_CONTENT: 'Para usar la aplicación debes permitir el acceso a la ubicación de tu dispositivo',
  ACTIVATE_LOCATION_SUB_CONTENT: 'Vas a recibir una solicitud de ubicación, por favor elige la opción “Permitir”',
  SUBTOTAL: 'Subtotal',
  INSTALLATION_CHARGE: 'Cargo de instalación',
  REAR_ID_SCANNING: 'TOMÁ LA FOTO POSTERIOR',
  PY_ACTIVATE_TEXT : 'Esta acción va a crear la cuenta del cliente, después de confirmar no va a ser posible realizar cambios.',
  CURRENCY: 'Gs.',
  OCR_DISMATCH_ERROR_TEXT: 'No pudimos extraer tu información de la cedula. Por favor intenta nuevamente.',
  OCR_ENTER_MANUALLY_TEXT: 'No pudimos extraer tu información de la cédula. Por favor ingresá manualmente los campos marcados.',
  PY_ACTIVATION_INSTALLATION_COST2: 'Abono inicial para instalación'
};
